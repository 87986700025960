<div class="flex items-center flex-col md:flex-row">
  <div class="md:mr-4 mb-4 md:mb-0">
    <svg-icon
      src="/assets/svg-icons/common/status-alert-circle.svg"
      [svgStyle]="{
        'width.px': 40,
        'height.px': 40,
        fill: '#F3D551',
        filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
      }"
    >
    </svg-icon>
  </div>
  <div class="text-center px-4 md:px-0">
    <span class="text-primary text-sm leading-5 me-1">{{
      'ACCOUNT.welcome' | translate: locale.language
    }}</span>
    <span
      class="text-sm leading-5"
      *ngIf="
        role === userRoleTypes.SERVICE_PROVIDER_BASIC ||
        role === userRoleTypes.SERVICE_PROVIDER_PRO
      "
      >{{
        'ACCOUNT.to publish your profile fill out mandatory'
          | translate: locale.language
      }}</span
    >
    <span
      class="text-sm leading-5"
      *ngIf="
        role === userRoleTypes.CLIENT_BASIC || role === userRoleTypes.CLIENT_PRO
      "
      >{{
        'ACCOUNT.to activate bookings fill out mandatory'
          | translate: locale.language
      }}</span
    >
  </div>
</div>
