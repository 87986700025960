import { Component, inject, OnInit } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { L10nTranslatePipe } from 'angular-l10n';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { FeatureWillBeEnabledComponent } from '@app/shared/components';
import { ExtendedModule } from '@angular/flex-layout/extended';
import { ReviewComponent } from '@app/shared/components/review/review.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  imports: [ExtendedModule, InfiniteScrollModule],
  selector: 'gingr-accept-referral',
  standalone: true,
  styleUrls: ['./accept-referral.component.scss'],
  templateUrl: './accept-referral.component.html',
})
export class AcceptReferralComponent implements OnInit {
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private readonly authService = inject(AuthService);

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          if (params.get('code')) {
            localStorage.setItem('referralCode', params.get('code'));
            return this.route.queryParams;
          } else {
            this.router.navigate(['/gingrs']);
            return of(null);
          }
        })
      )
      .subscribe((params: Params) => {
        if (!params) {
          return;
        }

        this.authService.loginWithRedirect({
          authorizationParams: {
            screen_hint: 'signup',
          },
          appState: {
            target: '/after-login',
          },
        });
      });
  }
}
