<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  class="mat-elevation-z6 main-header-toolbar"
>
  <button
    class="hamburger hamburger--slider"
    [class.is-active]="isMenuOpen && !isMenuClosed"
    type="button"
    fxLayout="column"
    fxLayout="center center"
    fxHide.lg
    fxHide.xl
    fxShow
    (click)="navOpen()"
  >
    <span class="hamburger-box">
      <span class="hamburger-inner"></span>
    </span>
  </button>

  <div
    *ngIf="showLogo"
    class="logo_container tooltip-wrapper flex flex-row justify-start items-start"
    [class.pro]="isProUser"
    (click)="redirectToMainPage()"
    [ngClass]="{ 'cursor-pointer': !isInsta }"
  >
    <div class="toolbar-logo logo-simple">
      <svg-icon src="/assets/images/gingr_logo.svg"></svg-icon>
    </div>
  </div>

  <div fxLayoutAlign="start center" fxLayout="row" fxHide fxShow.xs fxShow.sm>
    <ng-container *ngTemplateOutlet="bookingsControls"></ng-container>
  </div>

  <ng-container *ngIf="routerState !== '/info/pricing'">
    <div *ngIf="navigationText" fxHide fxShow.xs>
      <h2 class="navigation-text">{{ navigationText }}</h2>
    </div>
  </ng-container>

  <ng-container
    *ngIf="routerState === '/info/pricing' && isMobile && !isAuthenticated"
  >
    <div
      class="logo_container tooltip-wrapper"
      [class.pro]="isProUser"
      fxLayout="row"
      fxLayoutAlign=" start"
    >
      <div *ngIf="!isProUser" routerLink="/gingrs" class="toolbar-logo">
        <svg-icon src="/assets/images/gingr_logo.svg"></svg-icon>
      </div>
      <div *ngIf="isProUser" routerLink="/gingrs" class="toolbar-logo">
        <svg-icon src="/assets/images/gingr_logo_pro.svg"></svg-icon>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="elementSettings.accountMenu.show"
    fxFlex="{{ elementSettings.accountMenu.width }}"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <gingr-account-menu fxHide class="account-menu"></gingr-account-menu>
  </div>

  <span class="flex-spacer"></span>

  <div class="header-right-icons-block">
    <div class="header-icon-block">
      <div fxHide.xs *ngIf="!isInsta">
        <div *ngIf="currentUser?.role !== 2; else gingr">
          <button
            class="ms-auto btn btn-sm btn-primary rounded shadow h-[44px] py-[13px] px-[23px] me-[48px] hidden md:flex"
            (click)="openInstantBookingPromo()"
          >
            {{ 'HEADER.Start instant booking' | translate: locale.language }}
          </button>
        </div>

        <ng-template #gingr>
          <button
            class="ms-auto btn btn-primary btn-sm bg-primary text-base-300 rounded shadow h-[44px] py-[13px] px-[23px] me-[48px] xl:min-w-[160px] hidden md:flex"
            (click)="openInstantBookingPromo()"
          >
            {{ 'COMMON.Find clients' | translate: locale.language }}
          </button>
        </ng-template>
      </div>

      <a
        class="header-icon-block notification-container me-4"
        *ngIf="isAuthenticated && !isInsta"
        [routerLink]="['/chat']"
      >
        <div class="notification-badge -mr-4" *ngIf="unreadChatMessages">
          <span>{{ unreadChatMessages }}</span>
        </div>
        <div class="notification-icon">
          <div class="flex flex-col justify-center items-center">
            <svg-icon
              src="/assets/svg-icons/chat/chat.svg"
              [svgStyle]="{ 'width.px': 24, 'height.px': 20 }"
            ></svg-icon>
            <span
              class="text-[#F49FFB] text-[9px] capitalize mt-[3px] leading-[1] hidden md:block"
            >
              {{ 'SIDENAV-MENU.Chat' | translate: locale.language }}
            </span>
          </div>
        </div>
      </a>
      <a
        class="header-icon-block notification-container me-4"
        *ngIf="isAuthenticated && !isInsta"
        [routerLink]="['/account/dashboard/notifications']"
      >
        <div class="notification-badge" *ngIf="unViewedNotifications">
          <span>{{ unViewedNotifications }}</span>
        </div>
        <div class="notification-icon">
          <div class="flex flex-col justify-center items-center">
            <svg-icon
              class="ignoreFill item-icon"
              src="/assets/images/notifications/notification_bell.svg"
              [svgStyle]="{ 'width.px': 18, 'height.px': 20 }"
            ></svg-icon>
            <span
              class="text-[#F49FFB] text-[9px] capitalize mt-[5px] leading-[1] hidden md:block"
            >
              {{ 'SIDENAV-MENU.Notifications' | translate: locale.language }}
            </span>
          </div>
        </div>
      </a>

      <button
        fxHide.xs
        class="tooltip-wrapper me-1"
        (click)="goToMap()"
        *ngIf="showMapIcon"
        mat-icon-button
      >
        <div class="flex items-center justify-center flex-col">
          <img src="/assets/icons/map-icon.svg" class="header-icon" />
          <span
            class="text-[#F49FFB] text-[9px] capitalize mt-[5px] leading-[1]"
          >
            {{
              'GINGR_PROFILE.TABS.View Map' | translate: locale.language
            }}</span
          >
        </div>
      </button>
    </div>
    <div *ngIf="showGridIcon" fxHide.xs>
      <button
        (click)="backToGrid()"
        [routerLink]="['/gingrs']"
        mat-icon-button
        class="me-1"
      >
        <div class="flex items-center justify-center flex-col">
          <img src="/assets/icons/grid-view-new.svg" class="header-icon" />
          <span
            class="text-[#F49FFB] text-[9px] capitalize mt-[5px] leading-[1]"
          >
            {{
              'GINGR_PROFILE.TABS.View Grid' | translate: locale.language
            }}</span
          >
        </div>
      </button>
    </div>

    <div
      *ngIf="showFilterIcon"
      class="filter-item me-1 hidden md:block relative"
    >
      <ng-container [ngSwitch]="isInsta">
        <ng-container *ngSwitchCase="true">
          <div
            *ngIf="filtersSelectedInsta$ | async as filtersSelected"
            class="absolute bg-[#ff0015] -top-1 left-[34px] rounded-full flex items-center justify-center px-1"
          >
            <span class="text-xs">{{
              filtersSelected > 9 ? '9+' : filtersSelected
            }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="false">
          <div
            *ngIf="filtersSelectedMainGrid$ | async as filtersSelectedMainGrid"
            class="absolute bg-[#ff0015] -top-1 left-[34px] rounded-full flex items-center justify-center px-1"
          >
            <span class="text-xs">{{
              filtersSelectedMainGrid >= 9 ? '9+' : filtersSelectedMainGrid
            }}</span>
          </div>
        </ng-container>
      </ng-container>

      <button
        mat-icon-button
        class="sidebar-toggle tooltip-wrapper filter"
        (click)="filterOpen()"
      >
        <div class="flex items-center justify-center flex-col">
          <img src="/assets/icons/filter-new.svg" class="header-icon" />
          <span
            class="text-[#F49FFB] text-[9px] capitalize mt-[5px] leading-[1]"
          >
            {{ 'FILTER.Filter' | translate: locale.language }}</span
          >
        </div>
      </button>
    </div>

    <!--DISABLED BY GIT-1862-->
    <div
      class="header-icon-block"
      *ngIf="languages?.length > 1 && !isAuthenticated && changeLanguage"
    >
      <button
        *ngIf="locale.language"
        mat-mini-fab
        [matMenuTriggerFor]="languageTemplate"
        class="languageSelectorNew"
      >
        <div class="flex items-center justify-center flex-col">
          <img src="/assets/icons/languange-icon.svg" />
          <span
            class="text-[#F49FFB] text-[9px] capitalize mt-[5px] leading-[1] hidden md:block"
            >{{ fullLanguageName }}
          </span>
        </div>
      </button>
      <mat-menu #languageTemplate="matMenu">
        <button
          mat-menu-item
          *ngFor="let language of languages"
          [value]="language.value"
          (click)="selectLocale(language.value, language.viewValue)"
          fxLayoutAlign="start center"
        >
          <img
            [src]="
              'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/2.8.0/flags/1x1/' +
              mapLang(language.value) +
              '.svg'
            "
            alt="{{ language.viewValue }}"
            class="language-selector__flag"
          />
          <span fxFlex="1 1 8px"></span>
          {{ language.viewValue }}
        </button>
      </mat-menu>
    </div>

    <div
      fxLayoutAlign="start center"
      fxLayout="row"
      fxHide.sm="{{ showLogo ? 'false' : 'true' }}"
      fxHide.xs="{{ showLogo ? 'false' : 'true' }}"
    >
      <ng-container *ngTemplateOutlet="bookingsControls"></ng-container>
    </div>

    <!-- Menu in case Select can't be styled well enough -->
  </div>
</mat-toolbar>

<ng-template #bookingsControls>
  <div *ngIf="routerState && !isPreICO" class="header-bookings-controls"></div>
</ng-template>

<div
  class="flex justify-center items-center md:hidden shadow rounded-full w-[52px] h-[52px] bottom-[90px] bg-base-300 fixed right-[15px]"
  *ngIf="showMapIcon || showGridIcon"
>
  <img
    alt="ic_filter@2x.png"
    src="/assets/instafeature/icons/map-view.svg"
    class="w-[22px] h-[20px]"
    (click)="goToMap()"
    *ngIf="showMapIcon"
  />
  <a (click)="backToGrid()" *ngIf="showGridIcon" [routerLink]="['/gingrs']">
    <img
      alt="ic_filter@2x.png"
      src="/assets/instafeature/icons/grid-view.svg"
      class="w-[22px] h-[20px]"
    />
  </a>
</div>
