import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileTileModel } from '@app/account/containers/profile-v2/models/profile-tile.model';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';

@Component({
  selector: 'gingr-profile-tile',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    SvgIconComponent,
    L10nTranslatePipe,
  ],
  templateUrl: './profile-tile.component.html',
  styleUrls: ['./profile-tile.component.scss'],
})
export class ProfileTileComponent {
  @Input() tileData: ProfileTileModel;

  readonly locale = inject(L10N_LOCALE);
}
