<a
  [routerLink]="['/account', 'profile', tileData.route]"
  class="block md:mb-[0px] group"
  routerLinkActive="active"
>
  <div
    class="flex flex-col bg-base-300 rounded shadow-xs relative py-4 px-[10px] md:w-32 md:h-32 items-center overflow-hidden aspect-square hover:bg-base-200 transition-all group-[.active]:bg-base-200"
  >
    <span
      class="first-letter:capitalize text-white text-sm md:text-xs font-medium leading-[14px] md:mb-[18px] mb-[25px] block text-center"
      >{{ tileData.title }}</span
    >
    <div
      class="absolute top-[50%] w-[24%] h-[24%] md:h-8 md:w-8 translate-y-[-50%] md:translate-y-[-16px]"
    >
      <svg-icon
        [src]="
          '/assets/svg-icons/onboarding/newIcons/' +
          tileData.mainSvgIcon +
          '.svg'
        "
        [svgStyle]="{
          'width.px': '100%',
          'height.px': '100%',
          fill: 'rgb(255,255,255)'
        }"
      >
      </svg-icon>
    </div>

    <span
      class="absolute block bottom-3.5 rounded first-letter:capitalize text-xs font-semibold bg-primary text-primary-content px-1.5"
      *ngIf="tileData.badge"
      >{{ tileData.badge | translate: locale.language }}</span
    >

    <div
      class="absolute w-[28px] h-[28px] md:w-[16px] md:h-[16px] right-2 bottom-4 group-[.active]:hidden"
      *ngIf="tileData.fillPercent === 100 && !tileData.statusSvgIcon?.url"
    >
      <svg-icon
        src="/assets/svg-icons/common/status-success-circle.svg"
        [svgStyle]="{
          'width.px': '100%',
          'height.px': '100%',
          fill: 'rgb(0,255,0)',
          filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
        }"
      >
      </svg-icon>
    </div>
    <div
      class="absolute w-[28px] h-[28px] md:w-[16px] md:h-[16px] right-2 bottom-4 group-[.active]:hidden"
      *ngIf="tileData.statusSvgIcon?.url"
    >
      <svg-icon
        [src]="tileData.statusSvgIcon.url"
        [svgStyle]="{
          'width.px': '100%',
          'height.px': '100%',
          fill: tileData.statusSvgIcon.color,
          filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
        }"
      >
      </svg-icon>
    </div>

    <span
      class="absolute bottom-0 w-100 h-1.5 bg-primary-content left-0"
    ></span>
    <span
      class="absolute bottom-0 w-100 h-1.5 z-10 left-0"
      [class.bg-[#03FF00]]="
        (!tileData.fillPercent || tileData.fillPercent === 100) &&
        !tileData.stripeColor
      "
      [class.bg-[#FFD640]]="
        tileData.fillPercent > 0 &&
        tileData.fillPercent < 100 &&
        !tileData.stripeColor
      "
      [style.width]="tileData.fillPercent + '%'"
      [style.background-color]="tileData.stripeColor"
    ></span>
  </div>
</a>
