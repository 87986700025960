import { DestroyRef, inject, Injectable } from '@angular/core';
import * as _ from 'lodash-es';
import { GridFilter } from '@app/shared/models/gridFilter';
import { ProfilesService } from '@app/shared/services/profiles.service';
import { BehaviorSubject, concatMap, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FilterCity } from '@app/shared/models/filter-city';
import { Store } from '@ngrx/store';
import { storeFilterCallType } from '@app/shared/actions/shared.actions';
import { ConnectType } from '@app/instafeature/models/connect-type';

@Injectable({
  providedIn: 'root',
})
export class FilterV2Service {
  readonly store = inject(Store);

  routeFilters: any = [
    {
      route: /gingrs/,
      role: [1, 2],
      name: 'Gingrs',
      roleLink: 'gingrs',
    },
    {
      route: /establishments/,
      role: [5, 6],
      name: 'Establishments',
      roleLink: 'establishments',
    },
    {
      route: /agencies/,
      role: [8, 9],
      name: 'Agencies',
      roleLink: 'agencies',
    },
  ];
  filterRegions: FilterCity[] = [
    {
      label: 'Aargau',
      administrativeAreas: ['Aargau'],
      selected: false,
    },
    {
      label: 'Appenzell Ausserrhoden',
      administrativeAreas: ['Appenzell Ausserrhoden'],
      selected: false,
    },
    {
      label: 'Appenzell Innerrhoden',
      administrativeAreas: ['Appenzell Innerrhoden'],
      selected: false,
    },
    {
      label: 'Basel-Landschaft',
      administrativeAreas: ['Basel-Landschaft'],
      selected: false,
    },
    {
      label: 'Basel-Stadt',
      administrativeAreas: ['Basel-Stadt'],
      selected: false,
    },
    {
      label: 'Bern',
      administrativeAreas: ['Bern'],
      selected: false,
    },
    {
      label: 'Freiburg',
      administrativeAreas: ['Fribourg'],
      selected: false,
    },
    {
      label: 'Genf',
      administrativeAreas: ['Genève'],
      selected: false,
    },
    {
      label: 'Glarus',
      administrativeAreas: ['Glarus'],
      selected: false,
    },
    {
      label: 'Graubünden',
      administrativeAreas: ['Graubünden'],
      selected: false,
    },
    {
      label: 'Jura',
      administrativeAreas: ['Jura'],
      selected: false,
    },
    {
      label: 'Luzern',
      administrativeAreas: ['Kanton Luzern'],
      selected: false,
    },
    {
      label: 'Neuenburg',
      administrativeAreas: ['Neuchâtel'],
      selected: false,
    },
    {
      label: 'Nidwalden',
      administrativeAreas: ['Nidwalden'],
      selected: false,
    },
    {
      label: 'Obwalden',
      administrativeAreas: ['Obwalden'],
      selected: false,
    },
    {
      label: 'Schaffhausen',
      administrativeAreas: ['Schaffhausen'],
      selected: false,
    },
    {
      label: 'Schwyz',
      administrativeAreas: ['Schwyz'],
      selected: false,
    },
    {
      label: 'Solothurn',
      administrativeAreas: ['Solothurn'],
      selected: false,
    },
    {
      label: 'St. Gallen',
      administrativeAreas: ['Sankt Gallen'],
      selected: false,
    },
    {
      label: 'Tessin',
      administrativeAreas: ['Ticino'],
      selected: false,
    },
    {
      label: 'Thurgau',
      administrativeAreas: ['Thurgau'],
      selected: false,
    },
    {
      label: 'Uri',
      administrativeAreas: ['Uri'],
      selected: false,
    },
    {
      label: 'Waadt',
      administrativeAreas: ['Vaud'],
      selected: false,
    },
    {
      label: 'Wallis',
      administrativeAreas: ['Valais'],
      selected: false,
    },
    {
      label: 'Zug',
      administrativeAreas: ['Zug'],
      selected: false,
    },
    {
      label: 'Zürich',
      administrativeAreas: ['Zürich'],
      selected: false,
    },
  ];
  filter: GridFilter;
  defaultPageLimit = 24;
  pageLimit = this.defaultPageLimit;
  updateSubject = new Subject<boolean>();
  updateCountSubject = new Subject<boolean>();
  openCloseFilter = new Subject<boolean>();
  destroyRef = inject(DestroyRef);
  currentRole: string;
  filtersSelected = new BehaviorSubject<number>(null);

  numberedFilters = [
    'callType',
    'readyNow',
    'favourites',
    'new',
    'verified',
    'video',
    'stdTest',
    'ageVerified',
    'gender',
    'sexuality',
    'priceLow',
    'ageLow',
    'services',
    'language',
    'hairColor',
    'ethnicity',
    'bodyType',
    'breastSize',
    'rating',
  ];
  listFilters = [
    'services',
    'language',
    'hairColor',
    'ethnicity',
    'bodyType',
    'gender',
    'sexuality',
    'breastSize',
  ];

  constructor(private profilesService: ProfilesService) {
    this.resetFilters([]);

    this.updateSubject
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        concatMap((countOnly: boolean) =>
          this.profilesService.getProfiles(this.filter, null, countOnly)
        )
      )
      .subscribe();

    this.updateCountSubject
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateSubject.next(true));
  }

  getRoleToLoadAndResetFilter(
    currentRouteUrl: string,
    pageLimit: number
  ): string {
    const tile = _.find(this.routeFilters, (el) => {
      return el.route.test(currentRouteUrl);
    });

    this.pageLimit = pageLimit;
    if (!this.filter) {
      this.resetFilters(tile.role, pageLimit);
    } else {
      this.filter.userRoles = tile.role;
      this.filter.pagination = {
        limit: pageLimit || this.defaultPageLimit,
        offset: 0,
      };
    }

    this.currentRole = tile.roleLink;
    return tile?.name;
  }

  resetFilters(userRoles?: number[], pageLimit?: number) {
    this.filter = {
      latitude: null,
      longitude: null,
      radius: null,
      administrativeAreas: null,
      userRoles,
      callType: null,
      readyNow: false,
      favourites: false,
      new: false,
      verified: false,
      video: false,
      stdTest: false,
      ageVerified: false,
      gender: [],
      sexuality: [],
      priceLow: 0,
      priceHigh: 500,
      ageLow: 18,
      ageHigh: 100,
      services: [],
      language: [],
      hairColor: [],
      ethnicity: [],
      bodyType: [],
      breastSize: [],
      search: null,
      rating: null,
      pagination: {
        limit: pageLimit || this.defaultPageLimit,
        offset: 0,
      },
      voice: false,
    };
  }

  setUserLocation(latitude: number, longitude: number) {
    if (!this.filter) {
      this.resetFilters();
    }

    this.filter.latitude = latitude;
    this.filter.longitude = longitude;
  }

  updateSearch(pageLimit?: number, offset?: number) {
    this.countSelectedFilters();
    this.filter.pagination.limit = pageLimit || this.pageLimit;
    this.filter.pagination.offset = offset || 0;

    if (this.filter.callType && this.filter.callType !== ConnectType.BOTH) {
      this.store.dispatch(
        storeFilterCallType({
          data: this.filter.callType,
        })
      );
    } else {
      this.store.dispatch(
        storeFilterCallType({
          data: null,
        })
      );
    }
    this.updateSubject.next(false);
  }

  updateCount() {
    this.updateCountSubject.next(true);
  }

  countSelectedFilters() {
    let count = 0;
    this.numberedFilters.forEach((filter) => {
      if (
        filter === 'priceLow' &&
        (this.filter.priceLow > 0 || this.filter.priceHigh < 500)
      ) {
        count++;
      } else if (
        filter === 'ageLow' &&
        (this.filter.ageLow > 18 || this.filter.ageHigh < 100)
      ) {
        count++;
      } else if (
        filter === 'priceLow' ||
        filter === 'priceHigh' ||
        filter === 'ageLow' ||
        filter === 'ageHigh'
      ) {
        return;
      } else if (filter === 'callType' && this.filter[filter]) {
        if (this.filter[filter] === 'Both') {
          count += 2;
        } else if (this.filter[filter].length > 0) {
          count++;
        }
      } else if (
        this.filter[filter]?.length > 0 &&
        this.listFilters.includes(filter)
      ) {
        count += this.filter[filter].length;
      } else {
        if (
          this.filter[filter] === true ||
          this.filter[filter] > 0 ||
          this.filter[filter]?.length > 0
        ) {
          count++;
        }
      }
    });
    this.filtersSelected.next(count);
  }
}
