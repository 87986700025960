import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import {
  getPendingBookingsCount,
  setPendingBookingsCount,
} from '@app/account/containers/bookings-v2/store/bookings-v2.actions';
import { HookUpProcessService } from '@app/shared/services';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class BookingsV2Effects {
  readonly hookUpProcessService = inject(HookUpProcessService);
  private readonly actions$ = inject(Actions);
  private readonly router = inject(Router);

  getPendingBookingsCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPendingBookingsCount),
      switchMap(() => this.hookUpProcessService.getBookingsCount()),
      map((result) => {
        if (this.router.url === '/account/bookings') {
          if (result.pending || !result.upcoming) {
            this.router.navigate(['/account/bookings/pending']);
          } else if (!result.pending && result.upcoming) {
            this.router.navigate(['/account/bookings/upcoming']);
          }
        }

        return setPendingBookingsCount({ count: result.pending });
      })
    )
  );
}
