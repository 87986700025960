<div class="fixed top-[64px] left-0 w-full right-0 z-50">
  <div
    class="mobileMenuTop flex h-[58px] bg-[#860b84] relative z-10"
    [ngClass]="!user ? 'bg-info-content' : 'bg-base-300'"
  >
    <div
      class="leftSection h-[58px] w-[60%] bg-[#ee9aff] left-0 absolute flex items-center justify-center"
      [ngClass]="!user ? 'bg-base-300' : 'bg-info-content'"
    >
      <a
        *ngIf="!user"
        (click)="login()"
        class="item itemLogin flex items-center"
      >
        <svg-icon
          src="/assets/images/nav/menu-login.svg"
          [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"
          class="item-icon"
        ></svg-icon>
        <span class="item-title text-white text-[16px] ml-[12px] font-medium">{{
          'SIDENAV-MENU.LOGIN' | translate: locale.language
        }}</span>
      </a>
    </div>

    <div class="userAction w-[40%] self-end flex items-center ml-auto h-[58px]">
      <a
        *ngIf="!!user"
        (click)="logoutEvent.emit()"
        class="logoutSection item flex items-center"
      >
        <svg-icon
          src="./assets/images/nav/menu-logout.svg"
          [svgStyle]="{ 'width.px': 32, 'height.px': 32 }"
          class="item-icon ignoreFill"
        ></svg-icon>
        <span
          class="item-title text-white text-[16px] ml-[12px] font-medium capitalize"
          >{{ 'SIDENAV-MENU.LOGOUT' | translate: locale.language }}</span
        >
      </a>

      <a *ngIf="!user" (click)="login('signup')" class="item">
        <span
          class="item-title text-base-100 text-[16px] ml-[12px] font-medium"
          >{{ 'ACCOUNT.MENU.Sign Up' | translate: locale.language }}</span
        >
      </a>
    </div>
  </div>

  <a
    *ngIf="!!user"
    [routerLink]="['/account', 'profile']"
    class="item profile-item"
  >
    <div
      class="profileContent py-0 px-[34px] flex items-end -mt-[20px] relative mb-[5px] bg-base-100"
    >
      <div
        class="avatar relative z-[15] shadow-md h-[70px] w-[70px] rounded-full bg-cover"
        [style.background-image]="'url(' + avatar + ')'"
      ></div>
    </div>
  </a>
</div>

<div
  class="mobileItem flex flex-col"
  [ngClass]="user ? 'mt-[125px]' : 'mt-[80px]'"
>
  <a
    [routerLink]="['/account', 'referral']"
    [class.open]="profileExpanded"
    class="item pb-[29px] mt-4 mb-1"
    (click)="profileExpanded = !profileExpanded"
    *ngIf="user"
    routerLinkActive="active"
  >
    <svg-icon
      src="/assets/images/nav/menu-referral.svg"
      class="item-icon"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span class="item-title">{{
      'ACCOUNT.MENU.Referral' | translate: locale.language
    }}</span>
  </a>

  <div class="dividerNew pt-[20px] px-[42px] pb-[10px]">
    <span
      class="block w-full text-[14px] font-medium text-info-content pb-1 border-b border-info-content"
      >{{ 'ACCOUNT.MENU.Search' | translate: locale.language }}</span
    >
  </div>

  <a [routerLink]="['/gingrs']" class="item">
    <svg-icon
      src="/assets/images/nav/menu-escorts.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
      class="item-icon"
    ></svg-icon>
    <span class="item-title">{{
      'ACCOUNT.MENU.Escorts' | translate: locale.language
    }}</span>
  </a>

  <ng-container *ngIf="false">
    <a [routerLink]="['/establishments']" class="item">
      <svg-icon
        src="/assets/images/nav/clubs-new.svg"
        [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
        class="item-icon"
      ></svg-icon>
      <span class="item-title">{{
        'ACCOUNT.MENU.Clubs' | translate: locale.language
      }}</span>
    </a>

    <a [routerLink]="['/agencies']" class="item">
      <svg-icon
        src="/assets/images/nav/ageencies-new.svg"
        [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
        class="item-icon"
      ></svg-icon>
      <span class="item-title">{{
        'ACCOUNT.MENU.Agencies' | translate: locale.language
      }}</span>
    </a>
  </ng-container>
</div>

<div class="dividerNew pt-[20px] px-[42px] pb-[10px]" *ngIf="!!user">
  <span
    class="block w-full text-[14px] font-medium text-info-content pb-1 border-b border-info-content"
    >{{ 'ACCOUNT.MENU.Account' | translate: locale.language }}</span
  >
</div>

<div class="mobileItem flex flex-col mb-20">
  <a
    *ngIf="!!user"
    (click)="profileExpanded = true; settingsExpanded = false"
    class="item profileItem"
    [class.active]="profileExpanded"
    [routerLink]="['/account', 'profile']"
    routerLinkActive="active"
  >
    <svg-icon
      class="ignoreFill item-icon"
      src="/assets/images/nav/menu-profile.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span class="item-title">{{
      'ACCOUNT.MENU.Profile' | translate: locale.language
    }}</span>

    <img
      src="./assets/images/menu/arrow-downThick.svg"
      alt="Arrow"
      class="profileArrow ml-auto arrow-padding"
      (click)="
        profileExpanded = !profileExpanded;
        $event.stopPropagation();
        $event.preventDefault()
      "
      *ngIf="
        baseRole === userAvailableRoles.gingr.baseRoleName ||
        baseRole === userAvailableRoles.agency.baseRoleName ||
        baseRole === userAvailableRoles.brothels.baseRoleName
      "
    />
  </a>

  <ng-container *ngIf="profileExpanded">
    <ng-container
      *ngIf="
        baseRole === userAvailableRoles.gingr.baseRoleName ||
        baseRole === userAvailableRoles.agency.baseRoleName ||
        baseRole === userAvailableRoles.brothels.baseRoleName
      "
    >
      <div class="settingsContainer">
        <div class="settingsContainer"></div>
        <a
          [routerLink]="['/account', 'profile', 'account-details']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{
            'ACCOUNT.Account Details' | translate: locale.language
          }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'languages']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{
            'ACCOUNT.Nationality & Languages' | translate: locale.language
          }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'location']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{ 'ACCOUNT.Location' | translate: locale.language }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'media']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{
            'ACCOUNT.Photos & Videos' | translate: locale.language
          }}</span>
        </a>
      </div>
    </ng-container>
    <ng-container *ngIf="baseRole === userAvailableRoles.gingr.baseRoleName">
      <div class="settingsContainer">
        <a
          [routerLink]="['/account', 'profile', 'pricing']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{ 'ACCOUNT.Pricing' | translate: locale.language }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'services']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{ 'ACCOUNT.Services' | translate: locale.language }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'availability']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{ 'ACCOUNT.Availability' | translate: locale.language }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'personal-details']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{
            'ACCOUNT.Personal Details' | translate: locale.language
          }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'verification']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{ 'ACCOUNT.Verification' | translate: locale.language }}</span>
        </a>
      </div>
    </ng-container>
    <div class="settingsContainer">
      <a
        *ngIf="baseRole === userAvailableRoles.brothels.baseRoleName"
        [routerLink]="['/account', 'profile', 'facilities']"
        class="sideMenu-item"
        routerLinkActive="active"
      >
        <span>{{ 'ACCOUNT.Facilities' | translate: locale.language }}</span>
      </a>
    </div>
    <ng-container
      *ngIf="
        baseRole === userAvailableRoles.agency.baseRoleName ||
        baseRole === userAvailableRoles.brothels.baseRoleName
      "
    >
      <div class="settingsContainer">
        <a
          [routerLink]="['/account', 'profile', 'opening-hours']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{
            'ACCOUNT.MENU.Opening Hours' | translate: locale.language
          }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'extra-details']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{
            'ACCOUNT.Extra details' | translate: locale.language
          }}</span>
        </a>
        <a
          [routerLink]="['/account', 'profile', 'default-gingr-settings']"
          class="sideMenu-item"
          routerLinkActive="active"
        >
          <span>{{
            'ACCOUNT.MENU.Default Gingr Settings' | translate: locale.language
          }}</span>
        </a>
      </div>
    </ng-container>
  </ng-container>

  <a
    [routerLink]="['/wallet/overview']"
    class="sideMenu-item"
    routerLinkActive="active"
    *ngIf="!!user"
  >
    <svg-icon
      src="/assets/images/nav/menu-credit-balance.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span class="first-letter:capitalize">{{
      'CREDIT.wallet' | translate: locale.language
    }}</span>
  </a>

  <a
    [routerLink]="['/account', 'bookings']"
    class="sideMenu-item"
    *ngIf="!!user"
    routerLinkActive="active"
  >
    <svg-icon
      src="/assets/images/nav/menu-bookings.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span>{{ 'ACCOUNT.MENU.Bookings' | translate: locale.language }}</span>
  </a>

  <a
    [routerLink]="['/account', 'reviews']"
    class="sideMenu-item"
    routerLinkActive="active"
    *ngIf="
      !!user &&
      (user.role === userRoles.SERVICE_PROVIDER_BASIC ||
        user.role === userRoles.SERVICE_PROVIDER_PRO)
    "
  >
    <svg-icon
      src="/assets/images/nav/menu-reviews.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span>{{ 'ACCOUNT.MENU.Reviews' | translate: locale.language }}</span>
  </a>

  <a
    [routerLink]="['/account', 'dashboard', 'notifications']"
    class="sideMenu-item"
    *ngIf="!!user"
    routerLinkActive="active"
  >
    <svg-icon
      src="/assets/images/nav/menu-notifications.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span>{{ 'SIDENAV-MENU.Notifications' | translate: locale.language }}</span>
  </a>

  <a
    *ngIf="baseRole === userAvailableRoles.gingr.baseRoleName && !!user"
    [routerLink]="['/workplace']"
    class="sideMenu-item"
    routerLinkActive="active"
  >
    <svg-icon
      src="/assets/images/nav/menu-profile.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span>{{ 'ACCOUNT.MENU.Workplace' | translate: locale.language }}</span>
  </a>

  <a
    *ngIf="
      (baseRole === userAvailableRoles.brothels.baseRoleName ||
        baseRole === userAvailableRoles.agency.baseRoleName) &&
      !!user
    "
    [routerLink]="['/manage-gingrs']"
    class="sideMenu-item"
    routerLinkActive="active"
  >
    <svg-icon
      src="/assets/images/nav/menu-profile.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span>{{ 'ACCOUNT.MENU.Manage Gingrs' | translate: locale.language }}</span>
  </a>

  <a
    *ngIf="featureHasGGCoin && !!user"
    class="sideMenu-item"
    routerLinkActive="active"
  >
    <svg-icon
      src="/assets/images/nav/menu-profile.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span>{{ 'SIDENAV-MENU.GGC Tokens' | translate: locale.language }}</span>
  </a>
  <a [routerLink]="['/chat']" class="sideMenu-item" routerLinkActive="active">
    <svg-icon
      src="/assets/svg-icons/chat/chat.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span>{{ 'SIDENAV-MENU.Chat' | translate: locale.language }}</span>
  </a>
  <a
    [routerLink]="['/account', 'settings']"
    class="sideMenu-item"
    [class.open]="settingsExpanded"
    *ngIf="!!user"
    (click)="settingsExpanded = true; profileExpanded = false"
    routerLinkActive="active"
  >
    <svg-icon
      src="/assets/images/nav/menu-settings.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
    ></svg-icon>
    <span>{{ 'ACCOUNT.MENU.Settings' | translate: locale.language }}</span>
  </a>

  <ng-container *ngIf="settingsExpanded">
    <div class="settingsContainer">
      <a
        *ngIf="
          featurePageSubscription &&
          baseRole === userAvailableRoles.gingr.baseRoleName
        "
        [routerLink]="['/account', 'dashboard', 'subscription']"
        class="sideMenu-item"
        routerLinkActive="active"
      >
        <span>{{
          'SIDENAV-MENU.Subscription' | translate: locale.language
        }}</span>
      </a>
      <a
        *ngIf="changeLanguage"
        [routerLink]="['/account', 'dashboard', 'language']"
        class="sideMenu-item"
        routerLinkActive="active"
      >
        <span>{{ 'SIDENAV-MENU.Languages' | translate: locale.language }}</span>
      </a>
      <a
        [routerLink]="['/account', 'dashboard', 'notification-settings']"
        class="sideMenu-item"
        routerLinkActive="active"
      >
        <span>{{
          'SIDENAV-MENU.Notifications' | translate: locale.language
        }}</span>
      </a>
      <a
        [routerLink]="['/account', 'dashboard', 'security']"
        class="sideMenu-item"
        routerLinkActive="active"
      >
        <span>{{ 'SIDENAV-MENU.Security' | translate: locale.language }}</span>
      </a>
    </div>
  </ng-container>

  <div class="dividerNew pt-[20px] px-[42px] pb-[10px]">
    <span
      class="block w-full text-[14px] font-medium text-info-content pb-1 border-b border-info-content"
      >{{ 'ACCOUNT.MENU.More' | translate: locale.language }}</span
    >
  </div>

  <a (click)="openIntercomSupport()" class="item">
    <svg-icon
      src="/assets/images/nav/menu-support.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
      class="item-icon"
    ></svg-icon>
    <span class="item-title">{{
      'SIDENAV-MENU.Support' | translate: locale.language
    }}</span>
  </a>

  <a [routerLink]="['/info']" class="item">
    <svg-icon
      src="/assets/images/nav/menu-info.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
      class="item-icon"
    ></svg-icon>
    <span class="item-title">{{
      'SIDENAV-MENU.INFO' | translate: locale.language
    }}</span>
  </a>

  <a [routerLink]="['/welcome']" class="item mb-8">
    <svg-icon
      src="/assets/images/nav/menu-how-it-works.svg"
      [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
      class="item-icon"
    ></svg-icon>
    <span class="item-title">{{
      'SIDENAV-MENU.How it works' | translate: locale.language
    }}</span>
  </a>
  <p
    class="build-id fixed bottom-[80px] left-[23px] text-[10px]"
    *ngIf="showBuildId"
  >
    build: {{ releaseId }}
  </p>
</div>
