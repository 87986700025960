export interface UserStdModel {
  filePath: string;
  createdAt: string;
  validFrom?: string;
  validTo?: string;
  approvalStatus: UserStdApprovalStatus;
}

export enum UserStdApprovalStatus {
  Pending,
  Approved,
  Rejected,
}
