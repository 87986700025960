import { catchError, map, tap } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { StorageService } from './storage.service';
import { User, UserAvailableRoles, UserTypeRole } from '@app/shared/models';
import { HttpClient } from '@angular/common/http';
import { BookingStates } from '@app/shared/models/enum/bookingstates';
import { UserRoles } from '@app/shared/models/enum/userroles';
import * as Sentry from '@sentry/angular-ivy';
import { ConfigService } from '@app/shared/services/config.service';
import {
  setBalanceAction,
  setCurrentUserAction,
  setCurrentUserPreferenceAction,
  setUserAction,
} from '@app/shared/actions/shared.actions';
import {
  getCurrentUser,
  getCurrentUserPreference,
} from '@app/shared/reducers/user.selectors';
import { MapUserSearchResult } from '@app/shared/models/map-user/map-user-search-result';
import { MapUser } from '@app/shared/models/map-user/map-user';
import { MapUserSearchCommand } from '@app/shared/models/map-user/map-user-search-command';
import { Router } from '@angular/router';
import { ChatUser } from '@app/shared/models/conversation/chat-user';
import { GridUserDetails } from '@app/shared/models/grid/grid-user-details';
import { CanBookModel } from '@app/shared/models/booking/can-book.model';
import {
  CurrentUser,
  ProfileApprovedStatus,
} from '@app/shared/models/current-user';
import {
  SetUserPreferenceCommand,
  UserPreferenceModel,
} from '@app/shared/models/user-preference.model';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '@app/shared/services/notification.service';
import { L10nTranslationService } from 'angular-l10n';
import { CommonService } from '@app/instafeature/services/common.service';
import { getInstaStateSelector } from '@app/instafeature/store/instafeature.selectors';
import { CreditService } from '@app/shared/services/credit.service';
import { IntercomService } from '@app/shared/services/intercom.service';
import { AuthService } from '@auth0/auth0-angular';
import { EnrollmentResponseModel } from '@app/account/containers/settings-v2/models/enrollment-response.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly dialog = inject(MatDialog);

  private followersSubject = new BehaviorSubject<Array<any>>([]);
  public followers = this.followersSubject.asObservable();

  public followerDeletedSubject = new BehaviorSubject<string>(null);
  public followerDeleted = this.followerDeletedSubject.asObservable();

  // Pro user
  private isProUserSubject = new BehaviorSubject<boolean>(false);
  public isProUser = this.isProUserSubject.asObservable();

  private roleSubject = new BehaviorSubject<any>({ isGuest: true });
  public role = this.roleSubject.asObservable();

  private first_time_wizard;
  // variable to know what parts of views need to display

  private allBookingsSubject = new Subject<any>();
  public allBookings = this.allBookingsSubject.asObservable();
  private bookingsSubject = new BehaviorSubject<any>(null);
  public bookings = this.bookingsSubject.asObservable();
  accessToken: string;
  readonly notifyService = inject(NotificationService);
  readonly translation = inject(L10nTranslationService);
  readonly commonService = inject(CommonService);
  readonly creditService = inject(CreditService);
  readonly instaState = this.store.selectSignal(getInstaStateSelector);

  readonly userPreference = this.store.selectSignal(getCurrentUserPreference);
  readonly user = this.store.selectSignal(getCurrentUser);

  escortInactiveAccountLimit = -1000;
  clientInactiveAccountLimit = 0;

  /**
   * @param {object} user
   * @returns {string} user base role name like "client" || "agency" etc
   */
  public static getUserBaseRole(user: any): string {
    let baseRole: string = 'default';

    if (user) {
      if (user.roleId !== undefined) {
        for (const role in UserAvailableRoles) {
          if (UserAvailableRoles[role].roles.indexOf(user.roleId) !== -1) {
            baseRole = role;
          }
        }
      } else if (user.role !== undefined) {
        // if no role id
        let roleId;
        // we found it by role name -> user.role
        for (const prop in UserRoles) {
          if (prop === user.role) {
            roleId = UserRoles[prop];
          }
        }
        // now we know Id of current role
        for (const role in UserAvailableRoles) {
          if (UserAvailableRoles[role].roles.indexOf(roleId) !== -1) {
            baseRole = role;
          }
        }
      }
    }

    return baseRole;
  }

  /**
   *
   * @param {string} checkingRole
   * @param {User} user
   * @returns {boolean}
   */
  public static checkUserRole(checkingRole: string, user: User): boolean {
    const currentUserBaseRole = UserService.getUserBaseRole(user);
    let checkResult = false;

    if (checkingRole === currentUserBaseRole) {
      checkResult = true;
    }

    return checkResult;
  }

  public static getUserRole(user: User): UserTypeRole {
    if (!user) {
      return { isGuest: true };
    } else {
      return {
        isClient: UserService.checkUserRole(
          UserAvailableRoles.client.baseRoleName,
          user
        ),
        isGingr: UserService.checkUserRole(
          UserAvailableRoles.gingr.baseRoleName,
          user
        ),
        isEstablishment: UserService.checkUserRole(
          UserAvailableRoles.brothels.baseRoleName,
          user
        ),
        isAgency: UserService.checkUserRole(
          UserAvailableRoles.agency.baseRoleName,
          user
        ),
      };
    }
  }

  public static getAllAvailableStatuses(): number[] {
    return [
      BookingStates.ACCEPTED,
      BookingStates.IN_PROGRESS,
      BookingStates.COMPLETED,
      BookingStates.CANCELED,
      BookingStates.DECLINED,
      BookingStates.PENDING,
      BookingStates.PRICE_REQUEST,
    ];
  }

  constructor(
    private http: HttpClient,
    private store: Store<any>,
    private storage: StorageService,
    private configService: ConfigService,
    private router: Router
  ) {}

  checkAuthentication() {
    const auth = inject(AuthService);
    auth.isAuthenticated$.subscribe((data) => {
      if (data) {
        this.getUser().subscribe((user) => {
          if (user) {
            this.store.dispatch(setCurrentUserAction({ data: user }));
          } else {
            this.store.dispatch(setUserAction({ data: null }));
          }
        });
      } else {
        this.store.dispatch(setUserAction({ data: null }));
        IntercomService.resetIntercom();
      }
    });
  }

  openInsta(currentUser: CurrentUser): string {
    if (currentUser?.id) {
      const roles = [1, 2, 3, 4];
      if (roles.includes(currentUser.role)) {
        if (currentUser.role === 1 || currentUser.role === 2) {
          if (!currentUser.live) {
            return 'INSTAFUCK_DIALOG.LOGIN_ACCOUNT';
          } else {
            this.router.navigateByUrl('/instafeature');

            return null;
          }
        } else if (currentUser.role === 3 || currentUser.role === 4) {
          if (!currentUser.live) {
            return 'INSTAFUCK_DIALOG.LOGIN_ACCOUNT';
          } else {
            this.router.navigateByUrl('/instafeature');

            return null;
          }
        }
      } else {
        return 'INSTAFUCK_DIALOG.WRONG_ROLE';
      }
    } else {
      return 'INSTAFUCK_DIALOG.LOGIN_ACCOUNT';
    }
  }

  getUser(): Observable<CurrentUser> {
    return this.http
      .get(`${this.configService.config.apiV3}/user/account/current`, {})
      .pipe(
        catchError(() => of(null)),
        tap((user: CurrentUser) => {
          const sentryUser = user
            ? {
                id: user.id.toString(10),
                email: user.email,
                username: user.displayName,
              }
            : null;
          Sentry.setUser(sentryUser);
        })
      );
  }

  getUserPreference(): Observable<UserPreferenceModel> {
    return this.http
      .get<UserPreferenceModel>(
        `${this.configService.config.apiV3}/user/preference`
      )
      .pipe(catchError(() => of(null)));
  }

  setUserPreference(
    command: SetUserPreferenceCommand
  ): Observable<UserPreferenceModel> {
    return this.http.patch<UserPreferenceModel>(
      `${this.configService.config.apiV3}/user/preference`,
      command
    );
  }

  update(user: User): void {
    this.store.dispatch(setUserAction({ data: user }));
  }

  /**
   * check and save user role
   * @param user
   */
  checkUserRole(user: CurrentUser) {
    if (user) {
      const userRole: any = {
        isClient:
          user.role === UserRoles.CLIENT_BASIC ||
          user.role === UserRoles.CLIENT_PRO,
        isGingr:
          user.role === UserRoles.SERVICE_PROVIDER_BASIC ||
          user.role === UserRoles.SERVICE_PROVIDER_PRO,
        isEstablishment:
          user.role === UserRoles.ESTABLISHMENT_BASIC ||
          user.role === UserRoles.ESTABLISHMENT_PRO,
        isAgency:
          user.role === UserRoles.AGENCY_BASIC ||
          user.role === UserRoles.AGENCY_PRO,
      };
      this.roleSubject.next(userRole);
      if (
        user.role === UserRoles.SERVICE_PROVIDER_PRO ||
        user.role === UserRoles.CLIENT_PRO ||
        user.role === UserRoles.ESTABLISHMENT_PRO ||
        user.role === UserRoles.AGENCY_PRO
      ) {
        this.isProUserSubject.next(true);
      }
    } else {
      this.roleSubject.next({ isGuest: true });
    }
  }

  removeProUserRole() {
    this.isProUserSubject.next(false);
  }

  addToFavourite(id: number) {
    return this.http.post(
      `${this.configService.config.apiV3}/favourite/user/${id}`,
      {}
    );
  }

  deleteFromFavorite(id: number) {
    return this.http.delete(
      `${this.configService.config.apiV3}/favourite/user/${id}`
    );
  }

  getLocalLastSeen() {
    return this.storage.get('lastSeen', true) || [];
  }

  canBook(
    profileId: number,
    userLocation: { latitude: number; longitude: number }
  ): Observable<CanBookModel> {
    return this.http
      .post<CanBookModel>(
        `${this.configService.config.apiV3}/bookings/canBook`,
        {
          userId: profileId,
          latitude: userLocation?.latitude,
          longitude: userLocation?.longitude,
        }
      )
      .pipe(catchError(() => of(null)));
  }

  resetFirstRunWizard() {
    this.first_time_wizard = false;
  }

  getUserAvatarFromApi(user: GridUserDetails) {
    return (
      user?.medias?.find((media) => media.isAvatar)?.media ||
      this.getUserDefaultAvatar()
    );
  }

  getCurrentUserAvatar(user: CurrentUser) {
    const avatarMedia = user?.medias?.find((media) => media.isAvatar)?.media;
    return avatarMedia
      ? `${this.configService.config.mediaCdnUrl.slice(0, -1)}${avatarMedia}`
      : this.getUserDefaultAvatar();
  }

  getUserDefaultAvatar() {
    return `/assets/images/profile/avatar-default-picture.svg`;
  }

  getUserDefaultAvatarShort() {
    return `/assets/images/profile/avatar-default-pink.svg`;
  }

  mapSearchUsers(
    searchFilter: MapUserSearchCommand
  ): Observable<MapUserSearchResult[]> {
    return this.http.post<MapUserSearchResult[]>(
      `${this.configService.config.apiV3}/user/map/search`,
      searchFilter
    );
  }

  getMapUser(userId: number): Observable<MapUser> {
    return this.http.get<MapUser>(
      `${this.configService.config.apiV3}/user/map/${userId}`
    );
  }

  getChatUser(slug: string): Observable<ChatUser> {
    return this.http
      .post<ChatUser>(`${this.configService.config.apiV3}/user/chat`, { slug })
      .pipe(catchError(() => of(null)));
  }

  updateUserActivity(): Observable<any> {
    return this.http.post(
      `${this.configService.config.apiV3}/user/activity`,
      {}
    );
  }

  blockUser(userId: number): Observable<any> {
    return this.http
      .post(`${this.configService.config.apiV3}/user/${userId}/block`, {})
      .pipe(catchError(() => of(null)));
  }

  unBlockUser(userId: number): Observable<any> {
    return this.http
      .post(`${this.configService.config.apiV3}/user/${userId}/unblock`, {})
      .pipe(catchError(() => of(null)));
  }

  openInstantBooking(state: any = {}, instaUrl = '/instafeature') {
    if (!this.user()) {
      this.router.navigate(['/instafeature', 'promo'], {
        state: { show: true },
      });
      return;
    }

    this.checkCreditBalance().subscribe((hasCredit) => {
      if (!hasCredit) {
        return;
      }

      if (
        this.user().profileApprovedStatus !== ProfileApprovedStatus.APPROVED
      ) {
        this.router.navigate(['/account/profile']);
        return;
      } else if (
        this.user().role === UserRoles.SERVICE_PROVIDER_BASIC ||
        this.user().role === UserRoles.SERVICE_PROVIDER_PRO
      ) {
        if (!this.userPreference().instantBookingPromoSkip) {
          this.setUserPreference({ instantBookingPromoSkip: true }).subscribe(
            (data) =>
              this.store.dispatch(
                setCurrentUserPreferenceAction({
                  data,
                })
              )
          );
          this.router.navigate(['/instafeature', 'promo'], {
            state: { showRules: true, show: true },
          });
          return;
        } else if (!this.userPreference().instantBookingRulesSkip) {
          this.router.navigate(['/instafeature', 'rules'], {
            state: { showRules: true, show: true },
          });
          return;
        }
      }
      this.router.navigate([instaUrl], { state });
    });
  }

  checkCreditBalance(): Observable<boolean> {
    return this.creditService.getBalance().pipe(
      map((credit) => {
        this.store.dispatch(setBalanceAction({ data: credit }));

        if (
          credit < this.clientInactiveAccountLimit &&
          (this.user()?.role === UserRoles.CLIENT_PRO ||
            this.user()?.role === UserRoles.CLIENT_BASIC)
        ) {
          this.router.navigate(['/wallet/overview']);
          return false;
        }

        if (
          credit < this.escortInactiveAccountLimit &&
          (this.user()?.role === UserRoles.SERVICE_PROVIDER_BASIC ||
            this.user()?.role === UserRoles.SERVICE_PROVIDER_PRO)
        ) {
          if (this.instaState()?.id) {
            return true;
          }
          this.router.navigate(['/wallet/overview']);
          return false;
        }

        return true;
      })
    );
  }

  deleteUserAccount(): Observable<any> {
    return this.http.delete(
      `${this.configService.config.apiV3}/user/account/delete-account`,
      {}
    );
  }

  changePassword(): Observable<{ url: string }> {
    return this.http
      .post<{
        url: string;
      }>(`${this.configService.config.apiV3}/user/change-password`, {})
      .pipe(catchError(() => of(null)));
  }

  getMfa(): Observable<EnrollmentResponseModel[]> {
    return this.http
      .get<
        EnrollmentResponseModel[]
      >(`${this.configService.config.apiV3}/user/mfa`)
      .pipe(catchError(() => of(null)));
  }

  enrollMfa(): Observable<{ url: string }> {
    return this.http
      .post<{
        url: string;
      }>(`${this.configService.config.apiV3}/user/mfa`, {})
      .pipe(catchError(() => of(null)));
  }

  disableMfa(): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.configService.config.apiV3}/user/mfa`)
      .pipe(catchError(() => of(false)));
  }

  checkVerificationEmail(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.configService.config.apiV3}/user/email-verify`)
      .pipe(catchError(() => of(false)));
  }

  resendVerificationEmail(): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.configService.config.apiV3}/user/email-verify`, {})
      .pipe(catchError(() => of(false)));
  }
}
