import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ConfigService } from '@app/shared/services/config.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private readonly auth = inject(AuthService);
  private readonly configService = inject(ConfigService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      !request.url.startsWith(this.configService.config.apiV3) &&
      !request.url.startsWith(this.configService.config.wsUrl)
    ) {
      return next.handle(request);
    }

    return this.auth.getAccessTokenSilently().pipe(
      mergeMap((token) => {
        const tokenReq = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` },
        });
        return next.handle(tokenReq);
      }),
      catchError(() => next.handle(request))
    );
  }
}
