export enum BookingStates {
  PENDING = 0,
  PRICE_REQUEST = 1,
  ACCEPTED = 2,
  DECLINED = 3,
  CANCELED = 4,
  IN_PROGRESS = 5,
  COMPLETED = 6,
  NOT_RESPONDED = 7,
}

export enum GingrBookingStates {
  Traveling,
  Pending,
  PriceRequest,
  Accepted,
  Arrived,
  InSession,
  Paused,
  ClientCanceled,
  ProviderCanceled,
  Declined,
  Finished,
  NoResponse,
  ClientFinishedEarly,
  ProviderFinishedEarly,
  PendingPayment,
  PaymentFailed,
}
