import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '@app/shared/models/constants';

@Injectable({
  providedIn: 'root',
})
export class StreamChatService {
  private readonly config = inject(APP_CONFIG);
  private readonly http = inject(HttpClient);

  constructor() {}
  registerUserToChat(): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${this.config.apiV3}/stream-chat/register-user-to-chat`,
        null
      )
      .pipe(catchError(() => of(null)));
  }

  createUserChannel(request): Observable<any> {
    return this.http
      .post<any>(
        `${this.config.apiV3}/stream-chat/create-user-channel`,
        request
      )
      .pipe(catchError(() => of(null)));
  }

  banUser(request): Observable<any> {
    return this.http
      .put<any>(`${this.config.apiV3}/stream-chat/ban-user`, request)
      .pipe(catchError(() => of(null)));
  }

  getStreamChatToken(): Observable<any> {
    return this.http
      .get<any>(`${this.config.apiV3}/stream-chat/get-token`)
      .pipe(catchError(() => of(null)));
  }

  addCurrentUserToGlobalTest(): Observable<any> {
    return this.http
      .put<any>(`${this.config.apiV3}/stream-chat/add-user-to-global`, null)
      .pipe(catchError(() => of(null)));
  }
}
