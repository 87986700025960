import { createReducer, on } from '@ngrx/store';
import { setPendingBookingsCount } from '@app/account/containers/bookings-v2/store/bookings-v2.actions';

export interface BookingsV2State {
  pendingBookingsCount: number;
}

export const initialBookingsV2: BookingsV2State = {
  pendingBookingsCount: undefined,
};

export const bookingsV2Reducer = createReducer(
  initialBookingsV2,
  on(setPendingBookingsCount, (state, { count }) => ({
    ...state,
    pendingBookingsCount: count,
  }))
);
