export interface NotificationModel {
  id: number;
  category: NotificationCategory;
  subcategory: NotificationSubcategory;
  senderEntityType: NotificationSenderEntity;
  sender: NotificationSenderModel;
  triggerDate: string;
  isViewed: boolean;
  isRead: boolean;
  content?: string;
  avatar?: string;
  timeText?: string;
  link?: string;
  params?: {};
  additionalId?: number;
}

export interface NotificationSenderModel {
  senderId: number;
  senderName: string;
  senderAvatar: string;
  senderSlug: string;
  languageCode?: string;
}

export enum NotificationCategory {
  ACCOUNT,
  ESTABLISHMENT,
  BOOKING,
  MANAGE_GINGRS,
  INSTANT_BOOKING,
  CHAT,
  WALLET,
}

export enum NotificationSubcategory {
  ACCOUNT_VALIDATED,
  ESTABLISHMENT_INVITED,
  PROFILE_APPROVED,
  PROFILE_VERIFIED,
  PROFILE_NOT_APPROVED,
  PROFILE_NOT_VERIFIED,
  NEW_BOOKING,
  GINGR_ACCEPT_BOOKING,
  GINGR_DECLINE_BOOKING,
  CLIENT_ACCEPT_PRICE,
  CLIENT_DECLINE_PRICE,
  CLIENT_PRICE_REQUEST,
  GINGR_PRICE_OFFER,
  PROFILE_APPROVED_MANAGED,
  PROFILE_NOT_APPROVED_MANAGED,
  CLIENT_BOOKING_REMINDER,
  GINGR_BOOKING_REMINDER,
  BOOKING_REVIEW,
  CONNECTION_REQUEST,
  CONNECTION_REQUEST_ACCEPTED_INDEPENDENT,
  CONNECTION_REQUEST_DECLINED,
  DISCONNECTION_INDEPENDENT,
  DISCONNECTION_MANAGED,
  CONNECTION_REQUEST_ACCEPTED_MANAGED,
  GINGR_RECONNECT_INDEPENDENT,
  GINGR_RECONNECT_MANAGED,
  NEW_MESSAGE,
  NEGATIVE_CREDIT_BALANCE,
  LOW_CREDIT_BALANCE,
  PENDING_CLIENT_BILL,
  CANCEL_BOOKING,
  CONFIRM_WHATSAPP,
  RECEIVED_REFERRAL_BONUS,
  PAYOUT_REQUEST_RECEIVED,
  PAYOUT_REQUEST_COMPLETED,
  PAYOUT_REQUEST_REJECTED,
  CHAT_NEW_MESSAGE,
  BOOKING_CONFIRMATION_SENT,
  BOOKING_PAYMENT_CONFIRMED,
  BOOKING_PAYMENT_CREDITED,
}

export enum NotificationSenderEntity {
  SYSTEM,
  USER,
}
