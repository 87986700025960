import { Component, effect, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getCurrentUser } from '@app/shared/reducers/user.selectors';
import { Store } from '@ngrx/store';
import { ProfileApprovedStatus } from '@app/shared/models/current-user';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { Router } from '@angular/router';
import { ProfileV2Component } from '@app/account/containers/profile-v2/profile-v2.component';
import { ReferralService } from '@app/account/containers/referral/referral.service';

@Component({
  selector: 'gingr-after-login',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './after-login.component.html',
  styleUrls: ['./after-login.component.scss'],
})
export class AfterLoginComponent {
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly referralService = inject(ReferralService);
  readonly currentUser = this.store.selectSignal(getCurrentUser);

  constructor() {
    effect(() => {
      if (this.currentUser()) {
        if (
          this.currentUser().profileApprovedStatus ===
          ProfileApprovedStatus.NOT_APPROVED
        ) {
          const referralCode = localStorage.getItem('referralCode');
          if (referralCode) {
            this.referralService
              .linkUserToReferral(+referralCode)
              .subscribe((result) => {
                if (result) {
                  localStorage.removeItem('referralCode');
                  localStorage.setItem(
                    ProfileV2Component.BrowseEscortKey,
                    referralCode
                  );
                  localStorage.setItem(
                    ProfileV2Component.BrowseEscortTimestampKey,
                    Date.now().toString()
                  );
                }
              });
          }

          this.router.navigateByUrl(
            this.currentUser().role === UserRoles.CLIENT_BASIC
              ? '/account/profile/account'
              : '/account/profile'
          );
        } else {
          this.router.navigateByUrl('/gingrs');
        }
      }
    });
  }
}
