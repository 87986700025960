import { createAction, props } from '@ngrx/store';

export const setPendingBookingsCount = createAction(
  '[Bookings] Set pending bookings count',
  props<{ count: number }>()
);

export const getPendingBookingsCount = createAction(
  '[Bookings] Get pending bookings count'
);
