import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { PaginatorModule } from 'primeng/paginator';
import { NgxSliderModule } from 'ngx-slider-v2';
import { SvgIconComponent } from 'angular-svg-icon';
import { ProfileApprovedStatus } from '@app/shared/models/current-user';

@Component({
  selector: 'mandatory-complete-not-live',
  standalone: true,
  imports: [
    CommonModule,
    L10nTranslatePipe,
    PaginatorModule,
    ReactiveFormsModule,
    NgxSliderModule,
    SvgIconComponent,
  ],
  templateUrl: './mandatory-complete-not-live.component.html',
  styleUrls: ['./mandatory-complete-not-live.component.scss'],
})
export class MandatoryCompleteNotLive {
  readonly locale = inject(L10N_LOCALE);
  profileApprovedStatusTypes = ProfileApprovedStatus;

  @Input() profileApprovedStatus: ProfileApprovedStatus;
  @Output() publicProfileEvent = new EventEmitter<boolean>();
}
