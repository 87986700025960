import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { PaginatorModule } from 'primeng/paginator';
import { NgxSliderModule } from 'ngx-slider-v2';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { SvgIconComponent } from 'angular-svg-icon';

@Component({
  selector: 'missing-mandatory-progress',
  standalone: true,
  imports: [
    CommonModule,
    L10nTranslatePipe,
    PaginatorModule,
    ReactiveFormsModule,
    RouterLink,
    NgxSliderModule,
    SvgIconComponent,
  ],
  templateUrl: './missing-mandatory-progress.component.html',
  styleUrls: ['./missing-mandatory-progress.component.scss'],
})
export class MissingMandatoryProgress {
  readonly userRoleTypes = UserRoles;
  readonly locale = inject(L10N_LOCALE);
  @Input() role: UserRoles;
}
