import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LocalStorageService } from '@app/core/local-storage/local-storage.service';
import { Place } from '@app/shared/models/hookup.model';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { APP_CONFIG, FeatureFlags } from '@app/shared/models/constants';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { HttpClient } from '@angular/common/http';
import { AvailableCommand } from '@app/shared/models/booking/available.command';
import { CreateBookingCommand } from '@app/shared/models/booking/create-booking.command';
import { BookingProviderModel } from '@app/shared/models/booking/book-provider.model';
import { catchError } from 'rxjs/operators';
import { BookingCommand } from '@app/shared/models/booking/booking.command';
import { CreateBookingResponseModel } from '@app/book/models/create-booking-response.model';
import { PendingBookings } from '@app/account/containers/bookings-v2/helpers/pending-bookings';

@Injectable({
  providedIn: 'root',
})
export class HookUpProcessService {
  private readonly config = inject(APP_CONFIG);
  private readonly http = inject(HttpClient);

  private order$ = new BehaviorSubject<any>({});
  public order = this.order$.asObservable();
  public availableDays = [];
  public instantAvailability: ConnectType;
  public appointmentAvailability: ConnectType;
  public selectedGingrId: number;

  private price$ = new BehaviorSubject<any>({
    price: 0,
    currency: '',
    symbol: null,
  });
  public price = this.price$.asObservable();
  public onlyForClientOpen = new BehaviorSubject(false);

  constructor(private localStorage: LocalStorageService) {}

  public updateOrder(order: any) {
    this.order$.next({ ...this.order$.getValue(), ...order });
  }

  public deleteOrder() {
    this.order$.next({});
    this.price$.next({ currency: 'N/A', price: 0, symbol: null });
  }

  public providerInfo(
    command: AvailableCommand
  ): Observable<BookingProviderModel> {
    return this.http
      .post<BookingProviderModel>(
        `${this.config.apiV3}/bookings/provider`,
        command
      )
      .pipe(catchError(() => of(null)));
  }

  public createBooking(
    command: BookingCommand
  ): Observable<CreateBookingResponseModel> {
    return this.http
      .post<CreateBookingResponseModel>(
        `${this.config.apiV3}/bookings`,
        command
      )
      .pipe(catchError(() => of(null)));
  }

  public retryBookingPayment(bookingId: number) {
    return this.http
      .post<string>(
        `${this.config.apiV3}/bookings/retry-payment/${bookingId}`,
        null
      )
      .pipe(
        catchError((error) => {
          console.log(error);
          return of(null);
        })
      );
  }

  public isInvoicePaymentActive(): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.config.apiV3}/bookings/invoice-payment-active`)
      .pipe(catchError(() => of(false)));
  }

  public sendOrder(command: CreateBookingCommand): Observable<any> {
    return this.http.post(`${this.config.apiV3}/bookings`, command);
  }

  public setPrice(price: number, currency: string, symbol?: string) {
    let extraServicesPrice = 0;
    if (this.lookOnOrder().extraServices) {
      this.lookOnOrder().extraServices.forEach((service: any) => {
        extraServicesPrice += service.price;
      });
    }
    this.price$.next({
      currency: currency,
      price: price + extraServicesPrice,
      symbol: symbol ? symbol : null,
    });
  }

  public addToPrice(value: number) {
    this.price$.next({
      ...this.price$.getValue(),
      ...{ price: this.price$.getValue().price + value },
    });
  }

  public removeFromPrice(value: number) {
    this.price$.next({
      ...this.price$.getValue(),
      ...{ price: this.price$.getValue().price - value },
    });
  }

  public lookOnOrder(): any {
    return this.order$.getValue();
  }

  public lookOnPrice(): any {
    return this.price$.getValue();
  }

  // Check if place exists in localstorage
  // If not then add to array and save to localstorage
  public saveRecentPlace(item: Place) {
    const recents = this.localStorage.getItem('recent_places');
    if (recents) {
      const r = recents;
      const recentsList = new Set(r.map((re) => re.formatted_address));
      const exists = recentsList.has(item.formatted_address);

      if (!exists) {
        if (r.length >= 5) {
          r.shift();
        }
        r.push(item);
        this.localStorage.setItem('recent_places', r);
      }
    } else {
      const arr = [];
      arr.push(item);
      this.localStorage.setItem('recent_places', arr);
    }
  }

  public userIsWhitelistedForBookings() {
    return !!StaticUtilsService.LdClient.variation(
      FeatureFlags.EnableBookings,
      false
    );
  }

  getBookingsCount(): Observable<PendingBookings> {
    return this.http
      .get<PendingBookings>(`${this.config.apiV3}/bookings/count`)
      .pipe(catchError(() => of(null)));
  }
}
