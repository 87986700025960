import { BookWhenType } from '@app/book/models/book-when-type';
import { createReducer, on } from '@ngrx/store';
import {
  clearBookTravelDistanceAction,
  setBookAddress,
  setBookDetailsReviewedAction,
  setBookMessageAction,
  setBookOutcallTypeAction,
  setBookPaymentMethodAction,
  setBookPlaceTypeAction,
  setBookProfileAction,
  setBookResetAction,
  setBookServices,
  setBookStepAction,
  setBookTimeDurationAction,
  setBookTravelDistanceAction,
  setBookWhenAction,
  setCallTypeAvailable,
  setCashAvailable,
  setOutcallTypeAvailable,
} from '@app/book/store/book.actions';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { BookingProviderModel } from '@app/shared/models/booking/book-provider.model';
import { OutcallType } from '@app/instafeature/models/outcall-type';
import { BookingUserAddress } from '@app/shared/models/booking/booking-details.model';
import { OutcallTypeBook } from '@app/book/models/outcall-type';
import { BookingPaymentMethod } from '@app/book/models/booking-payment-method';

export interface BookState {
  when?: BookWhenType;
  step?: number;
  profileId?: number;
  profile?: BookingProviderModel;
  placeType?: ConnectType;
  outcallType?: OutcallType;
  paymentMethod?: BookingPaymentMethod;
  duration?: number;
  startDate?: Date;
  services: number[];
  message: string;
  detailsReviewed: boolean;
  travelDistance: number;
  address: BookingUserAddress;
  callTypeAvailable: ConnectType;
  distanceId: string;
  cashAvailable: boolean;
  outcallTypeAvailable: OutcallTypeBook;
}

export const initialBookState: BookState = {
  when: undefined,
  step: undefined,
  profileId: undefined,
  profile: undefined,
  placeType: undefined,
  outcallType: undefined,
  paymentMethod: undefined,
  duration: undefined,
  startDate: undefined,
  services: undefined,
  message: undefined,
  detailsReviewed: undefined,
  travelDistance: undefined,
  address: undefined,
  callTypeAvailable: undefined,
  distanceId: undefined,
  cashAvailable: undefined,
  outcallTypeAvailable: undefined,
};

export const bookReducer = createReducer(
  initialBookState,
  on(setBookResetAction, () => ({
    ...initialBookState,
  })),
  on(setBookStepAction, (state, { step }) => ({
    ...state,
    step: step,
  })),
  on(setBookProfileAction, (state, { profile }) => ({
    ...state,
    profile: profile,
  })),
  on(setBookWhenAction, (state, { when, profileId }) => ({
    ...state,
    when: when,
    profileId: profileId,
  })),
  on(setBookPlaceTypeAction, (state, { placeType }) => ({
    ...state,
    placeType: placeType,
  })),
  on(setBookPaymentMethodAction, (state, { paymentMethod }) => ({
    ...state,
    paymentMethod,
  })),
  on(setBookOutcallTypeAction, (state, { outcallType }) => ({
    ...state,
    outcallType: outcallType,
  })),
  on(setBookTimeDurationAction, (state, { startDate, duration }) => ({
    ...state,
    startDate: startDate,
    duration: duration,
  })),
  on(setBookDetailsReviewedAction, (state, { detailsReviewed }) => ({
    ...state,
    detailsReviewed,
  })),
  on(setBookTravelDistanceAction, (state, { travelDistance, distanceId }) => ({
    ...state,
    travelDistance,
    distanceId,
  })),
  on(clearBookTravelDistanceAction, (state) => ({
    ...state,
    travelDistance: undefined,
  })),
  on(setBookServices, (state, { services }) => ({
    ...state,
    services,
  })),
  on(setBookMessageAction, (state, { message }) => ({
    ...state,
    message,
  })),
  on(setBookAddress, (state, { address }) => ({
    ...state,
    address,
  })),
  on(setCallTypeAvailable, (state, { callTypeAvailable }) => ({
    ...state,
    callTypeAvailable,
  })),
  on(setCashAvailable, (state, { cashAvailable }) => ({
    ...state,
    cashAvailable,
  })),
  on(setOutcallTypeAvailable, (state, { outcallTypeAvailable }) => ({
    ...state,
    outcallTypeAvailable,
  }))
);
