import { Config, GetLoc, SummaryLocation, User } from '@app/shared/models';
import { createReducer, on } from '@ngrx/store';
import {
  addUnViewedNotificationsAction,
  disableRealAgeAction,
  enableRealAgeAction,
  setAuthTokenAction,
  setBalanceAction,
  setBrowseUser,
  setCurrentUserAction,
  setCurrentUserPreferenceAction,
  setGridProfilesFiltersAction,
  setHasNotificationSetAction,
  setIntercomBannerVisible,
  setIntercomVisible,
  setKycCardVerificationAction,
  setKycVerificationAction,
  setManagedUserAction,
  setUnViewedNotificationsAction,
  setUserAction,
  setUserEmailVerifiedAction,
  setUserStdTestAction,
  storeAppConfigAction,
  storeFilterCallType,
  storeFilterLocation,
  storeUserLocationAction,
} from '@app/shared/actions/shared.actions';
import { CurrentUser } from '@app/shared/models/current-user';
import { UserPreferenceModel } from '@app/shared/models/user-preference.model';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { DataSpikeVerificationModel } from '@app/shared/models/data-spike/data-spike-verification.model';
import { BrowseUser } from '@app/shared/models/browse-user';
import { UserStdModel } from '@app/account/containers/profile-v2/models/user-std.model';

export interface SharedState {
  appConfig: Config;
  gridProfilesFilters: GetLoc;
  userLocation: SummaryLocation;
  filterLocation: SummaryLocation;
  filterCallType: ConnectType;
  user: User;
  managedUser: User;
  currentUser: CurrentUser;
  currentUserPreference: UserPreferenceModel;
  stdTest: UserStdModel;
  hasNotificationSet: boolean;
  kcyVerification: DataSpikeVerificationModel;
  kcyCardVerification: DataSpikeVerificationModel;
  accessToken: string;
  unViewedNotifications: number;
  intercomVisible: boolean;
  browseUserAfterApprovingProfile: BrowseUser;
  showIntercomBanner: boolean;
}

export const initialSharedState: SharedState = {
  appConfig: undefined,
  gridProfilesFilters: new GetLoc(),
  userLocation: undefined,
  filterLocation: undefined,
  filterCallType: undefined,
  user: undefined,
  currentUser: undefined,
  currentUserPreference: undefined,
  stdTest: undefined,
  managedUser: undefined,
  hasNotificationSet: undefined,
  kcyVerification: undefined,
  kcyCardVerification: undefined,
  accessToken: undefined,
  unViewedNotifications: undefined,
  intercomVisible: undefined,
  browseUserAfterApprovingProfile: undefined,
  showIntercomBanner: undefined,
};

export const sharedReducer = createReducer(
  initialSharedState,
  on(storeAppConfigAction, (state, { data }) => ({
    ...state,
    appConfig: data,
  })),
  on(setGridProfilesFiltersAction, (state, { data }) => ({
    ...state,
    gridProfilesFilters:
      !(
        state.gridProfilesFilters?.latitude &&
        state.gridProfilesFilters?.longitude
      ) ||
      (data?.latitude && data?.longitude)
        ? data
        : state.gridProfilesFilters,
  })),
  on(storeUserLocationAction, (state, { data }) => ({
    ...state,
    userLocation: data,
    filterLocation: state.filterLocation || data,
  })),
  on(storeFilterCallType, (state, { data }) => ({
    ...state,
    filterCallType: data,
  })),
  on(storeFilterLocation, (state, { data }) => ({
    ...state,
    filterLocation: data,
  })),
  on(setBalanceAction, (state, { data }) => ({
    ...state,
    currentUser: { ...state.currentUser, creditBalance: data },
  })),
  on(setUserAction, (state, { data }) => ({
    ...state,
    user: data,
  })),
  on(setUserEmailVerifiedAction, (state, { verified }) => ({
    ...state,
    currentUser: { ...state.currentUser, emailVerification: !!verified },
  })),
  on(setHasNotificationSetAction, (state, { hasNotificationSet }) => ({
    ...state,
    hasNotificationSet: hasNotificationSet,
  })),
  on(setKycVerificationAction, (state, { verification }) => ({
    ...state,
    kcyVerification: verification,
  })),
  on(setKycCardVerificationAction, (state, { verification }) => ({
    ...state,
    kcyCardVerification: verification,
  })),
  on(setCurrentUserAction, (state, { data }) => ({
    ...state,
    currentUser: data,
  })),
  on(enableRealAgeAction, (state, { dob }) => ({
    ...state,
    currentUser: {
      ...state.currentUser,
      verifiedDob: dob,
      showVerifiedAge: true,
    },
  })),
  on(disableRealAgeAction, (state) => ({
    ...state,
    currentUser: { ...state.currentUser, showVerifiedAge: false },
  })),
  on(setCurrentUserPreferenceAction, (state, { data }) => ({
    ...state,
    currentUserPreference: data,
  })),
  on(setUserStdTestAction, (state, { data }) => ({
    ...state,
    stdTest: data,
  })),
  on(setManagedUserAction, (state, { data }) => ({
    ...state,
    managedUser: data,
  })),
  on(setAuthTokenAction, (state, { accessToken }) => ({
    ...state,
    accessToken: accessToken,
  })),
  on(setUnViewedNotificationsAction, (state, { count }) => ({
    ...state,
    unViewedNotifications: count,
  })),
  on(setIntercomVisible, (state, { data }) => ({
    ...state,
    intercomVisible: data,
  })),
  on(setBrowseUser, (state, { data }) => ({
    ...state,
    browseUserAfterApprovingProfile: data,
  })),
  on(addUnViewedNotificationsAction, (state, { count }) => ({
    ...state,
    unViewedNotifications: Math.max(
      0,
      (state?.unViewedNotifications || 0) + count
    ),
  })),
  on(setIntercomBannerVisible, (state, { showIntercomBanner }) => ({
    ...state,
    showIntercomBanner,
  }))
);
