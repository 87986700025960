import {
  PhysicalDetails,
  UserAvailability,
  UserLanguage,
  UserPreferences,
  UserPricing,
  UserService,
} from '@app/shared/models/grid/grid-user-details';
import { UserMedia } from '@app/shared/models/grid/grid-user';
import { UserAddressModel } from '@app/shared/models/user-address.model';
import { BlockedCountryModel } from '@app/shared/models/blocked-country.model';
import { UserVoice } from '@app/shared/UserVoice';

export interface CurrentUser {
  id: number;
  blockedCountries: BlockedCountryModel[];
  displayName: string;
  email: string;
  gender: number;
  emailVerification: boolean;
  mobile: string;
  authUserId: string;
  role: number;
  nationality: string;
  languages: UserLanguage[];
  services: UserService[];
  birthYear: number;
  verifiedDob?: string;
  sexuality: number;
  profileApprovedStatus: ProfileApprovedStatus;
  pricing: UserPricing;
  kycVerified: boolean;
  kycCardVerified: boolean;
  showVerifiedAge: boolean;
  medias: UserMedia[];
  live: boolean;
  disabled: boolean;
  endVacationDate: string;
  verified: boolean;
  aboutMe: string;
  occupation: string;
  bookingNotes: string;
  slug: string;
  preferences: UserPreferences;
  physicalDetails: PhysicalDetails;
  bodyTypes: number[];
  availability: UserAvailability[];
  identifyStatus: IdentifyStatus;
  addresses: UserAddressModel[];
  locations: UserLocations;
  langIso: string;
  language: number;
  intercomHmac: string;
  creditBalance: number;
  cardOrdered: boolean;
  rating: number;
  overdueInvoices: boolean;
  paymentStatus: PaymentStatus;
  userVoice: UserVoice;
}

export enum PaymentStatus {
  Active = 0,
  OverdueInvoices = 1,
  OverdueInvoicesLimit = 2,
}

export enum ProfileApprovedStatus {
  NOT_APPROVED = 0,
  PENDING_APPROVAL = 1,
  APPROVED = 2,
  EDITED = 3,
}

export enum IdentifyStatus {
  PENDING = 0,
  REJECTED = 1,
  COMPLETE = 2,
}

export interface UserLocations {
  event: boolean;
  home: boolean;
  hotel: boolean;
}
