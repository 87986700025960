import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getAppConfigAction,
  getBalanceAction,
  getCurrentUserAction,
  getCurrentUserPreferenceAction,
  getHasNotificationSetAction,
  getKycVerificationAction,
  getUnViewedNotificationsAction,
  getUserLocationAction,
  setCurrentUserAction,
  setCurrentUserPreferenceAction,
  setHasNotificationSetAction,
  setKycVerificationAction,
  setUnViewedNotificationsAction,
  setBalanceAction,
  storeAppConfigAction,
  storeUserLocationAction,
  getKycCardVerificationAction,
  setKycCardVerificationAction,
  getUserStdTestAction,
  setUserStdTestAction,
} from '@app/shared/actions/shared.actions';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  AppService,
  LocationService,
  UserNotificationsService,
  UserService,
} from '@app/shared/services';
import { Config, SummaryLocation } from '@app/shared/models';
import { from, of, zip } from 'rxjs';
import { StaticUtilsService } from '@app/shared/services/static-utils.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MapService } from '@app/instafeature/services/map.service';
import { Store } from '@ngrx/store';
import { getUserLocation } from '@app/shared/reducers/shared.selectors';
import { NotificationSettingType } from '@app/shared/models/notification-setting.model';
import { IntercomService } from '@app/shared/services/intercom.service';
import { getIntercomVisible } from '@app/shared/reducers/user.selectors';
import { DataspikeService } from '@app/shared/services/dataspike.service';
import { CreditService } from '@app/shared/services/credit.service';
import { KycVerificationType } from '@app/shared/models/data-spike/kyc-verification.type';
import { StreamChatService } from '@app/shared/services/stream-chat.service';
import { AccountService } from '@app/account/services';
import { MaxMindCityModel } from '@app/shared/models/max-mind-city.model';

@Injectable({
  providedIn: 'root',
})
export class SharedEffects {
  private readonly intercomService = inject(IntercomService);
  private readonly userService = inject(UserService);
  private readonly dataspikeService = inject(DataspikeService);
  private readonly creditService = inject(CreditService);
  private readonly accountService = inject(AccountService);
  private readonly streamChatService = inject(StreamChatService);

  getAppConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppConfigAction),
      switchMap(() => this.appService.getAppConfig()),
      map((data: Config) => {
        return storeAppConfigAction({ data });
      })
    )
  );

  getHasNotificationSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[getHasNotificationSetAction, setCurrentUserAction]),
      switchMap(() => this.userNotificationService.getNotificationSettings()),
      map((data) => {
        if (
          data.some(
            (e) =>
              e.notificationType === NotificationSettingType.Push && !!e.enabled
          )
        ) {
          this.userNotificationService.requestPushToken();
        } else {
          this.userNotificationService.isListeningToPush = false;
        }

        return setHasNotificationSetAction({
          hasNotificationSet: data.some(
            (e) =>
              !!e.enabled &&
              (!e.uninitialized ||
                e.notificationType !== NotificationSettingType.Telegram)
          ),
        });
      })
    )
  );

  getKycVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getKycVerificationAction),
      switchMap(() =>
        this.dataspikeService.getVerification(KycVerificationType.Profile)
      ),
      map((data) => setKycVerificationAction({ verification: data }))
    )
  );

  getKycCardVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getKycCardVerificationAction),
      switchMap(() =>
        this.dataspikeService.getVerification(KycVerificationType.Card)
      ),
      map((data) => setKycCardVerificationAction({ verification: data }))
    )
  );

  getUserLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserLocationAction),
      switchMap(({ askAccess }) =>
        this.geolocationService.getUserLocation(askAccess)
      ),
      switchMap((data: SummaryLocation) => {
        if (data?.isFallback) {
          return this.geolocationService.getLocationByIp().pipe(
            map((cityData: MaxMindCityModel) =>
              cityData?.location?.latitude && cityData?.location?.longitude
                ? <SummaryLocation>{
                    latitude: cityData.location.latitude,
                    longitude: cityData.location.longitude,
                    city: cityData.city.name,
                    country: cityData.country.isoCode,
                    countryname: cityData.country.name,
                    postalCode: cityData.postal.code,
                  }
                : data
            )
          );
        } else {
          return of(data);
        }
      }),
      map((data: SummaryLocation) => {
        this.mapService.setMapStyle(data);
        return storeUserLocationAction({ data });
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCurrentUserAction),
      switchMap(() => this.userService.getUser()),
      map((result) => setCurrentUserAction({ data: result }))
    )
  );

  getUserBalance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBalanceAction),
      switchMap(() => this.creditService.getBalance()),
      map((result) => setBalanceAction({ data: result }))
    )
  );

  getUserStdTest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserStdTestAction),
      switchMap(() => this.accountService.getStdTest()),
      map((result) => setUserStdTestAction({ data: result }))
    )
  );

  getUserPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(...[setCurrentUserAction, getCurrentUserPreferenceAction]),
      switchMap(() => this.userService.getUserPreference()),
      map((result) => setCurrentUserPreferenceAction({ data: result }))
    )
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentUserAction),
      switchMap((action) =>
        zip(of(action), this.store.select(getUserLocation))
      ),
      map(([action, userLocation]) => {
        const deviceInfo = this.deviceService.getDeviceInfo();
        const deviceTypeContext = {
          kind: 'deviceType',
          key: deviceInfo.deviceType,
        };

        const multiContext: any = {
          kind: 'multi',
          user: action.data
            ? {
                kind: 'user',
                key: action.data.authUserId,
                name: action.data.displayName,
                email: action.data.email,
              }
            : {
                kind: 'user',
                anonymous: true,
                key: 'anon-user',
              },
          deviceType: deviceTypeContext,
        };

        if (userLocation) {
          multiContext.location = {
            kind: 'location',
            key: userLocation.country,
            country: userLocation.country,
            countryName: userLocation.countryname,
          };
        }

        StaticUtilsService.LdClient.identify(multiContext);

        if (action.data) {
          this.userService.updateUserActivity().subscribe();
          this.streamChatService.registerUserToChat().subscribe();
        }

        this.userService.checkUserRole(action.data);
        return action.data
          ? getUnViewedNotificationsAction()
          : setUnViewedNotificationsAction({ count: 0 });
      })
    )
  );

  getUnViewedNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUnViewedNotificationsAction),
      switchMap(() => this.userNotificationService.getUnViewedNotifications()),
      map((count: number) => setUnViewedNotificationsAction({ count }))
    )
  );

  updateIntercom$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentUserAction),
      filter((data) => !!data?.data),
      withLatestFrom(this.store.select(getIntercomVisible)),
      map(([_, intercomVisible]) => {
        this.intercomService.intercom(intercomVisible);
        return null;
      }),
      filter((action) => !!action)
    )
  );

  constructor(
    private actions$: Actions,
    private appService: AppService,
    private userNotificationService: UserNotificationsService,
    private geolocationService: LocationService,
    private deviceService: DeviceDetectorService,
    private mapService: MapService,
    private store: Store
  ) {}
}
