import { inject, Injectable } from '@angular/core';
import { ConfigService } from '@app/shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  PaymentMethod,
  TransactionModel,
  TransactionStatus,
  TransactionType,
} from '@app/wallet/models/transaction.model';
import { TransactionDetailsModel } from '@app/wallet/models/transaction-details.model';
import { PayoutType } from '@app/wallet/models/payout-type';
import { CreditworthModel } from '@app/wallet/models/creditworth.model';
import { GetTransactionsCommand } from '@app/shared/models/credit/get-transactions.command';
import { TopUpResponseModel } from '@app/wallet/models/top-up-response.model';

@Injectable({
  providedIn: 'root',
})
export class CreditService {
  private readonly configService = inject(ConfigService);
  private readonly http = inject(HttpClient);

  getBalance(): Observable<number> {
    return this.http
      .get<number>(`${this.configService.config.apiV3}/wallet`)
      .pipe(catchError(() => of(null)));
  }

  getPending(): Observable<number> {
    return this.http
      .get<number>(`${this.configService.config.apiV3}/wallet/pending`)
      .pipe(catchError(() => of(null)));
  }

  topUp(
    amount: number,
    paymentMethod: PaymentMethod = PaymentMethod.BankTransfer
  ): Observable<TopUpResponseModel> {
    return this.http
      .post<TopUpResponseModel>(
        `${this.configService.config.apiV3}/wallet/top-up`,
        {
          amount,
          paymentMethod,
        }
      )
      .pipe(catchError(() => of(null)));
  }

  getTransactionList(
    command: GetTransactionsCommand
  ): Observable<TransactionModel[]> {
    return this.http
      .post<
        TransactionModel[]
      >(`${this.configService.config.apiV3}/wallet/list`, command)
      .pipe(catchError(() => of([])));
  }

  getUnpaidBalanceSettlement() {
    return this.http
      .get<TransactionDetailsModel>(
        `${this.configService.config.apiV3}/wallet/balance-settlement`
      )
      .pipe(catchError(() => of(null)));
  }

  getTransaction(id: number): Observable<TransactionDetailsModel> {
    return this.http
      .get<TransactionDetailsModel>(
        `${this.configService.config.apiV3}/wallet/${id}`
      )
      .pipe(catchError(() => of(null)));
  }

  getCardDashboardUrl(): Observable<{ url: string }> {
    return this.http
      .get<{
        url: string;
      }>(`${this.configService.config.apiV3}/wallet/card/dashboard`)
      .pipe(catchError(() => of({ url: null })));
  }

  addPayout(
    amount: number,
    payoutType: PayoutType,
    description?: string
  ): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.configService.config.apiV3}/wallet/payout`, {
        description,
        amount,
        payoutType,
      })
      .pipe(catchError(() => of(false)));
  }

  orderCard(deliveryMethod: string, details: string): Observable<any> {
    return this.http.post(
      `${this.configService.config.apiV3}/wallet/card/order`,
      { deliveryMethod, details }
    );
  }

  getCreditworth(): Observable<CreditworthModel> {
    return this.http
      .get<CreditworthModel>(
        `${this.configService.config.apiV3}/wallet/creditworth`
      )
      .pipe(catchError(() => of(null)));
  }

  checkCreditworth(
    streetName: string,
    city: string,
    countryCode3: string,
    streetNumber?: string,
    postalCode?: string
  ): Observable<CreditworthModel> {
    return this.http
      .post<CreditworthModel>(
        `${this.configService.config.apiV3}/wallet/creditworth`,
        { streetName, city, countryCode3, streetNumber, postalCode }
      )
      .pipe(catchError(() => of(null)));
  }
}
