<div class="sidenavWrapper" [class.hasOpenItems]="extraSidebarVisible">
  <div
    fxLayout="column"
    class="skewShadow"
    [ngClass]="!user ? 'pt-[20px]' : 'pt-[6px]'"
    [ngStyle]="{ height: 'calc(100% - ' + sideNavSubtractHeightPx() + 'px)' }"
  >
    <div class="mainMenu">
      <a
        *ngIf="!!user"
        [routerLink]="['/account', 'profile']"
        class="item profile-item !mb-0"
      >
        <div class="profileContent">
          <div
            class="avatar"
            [style.background-image]="'url(' + avatar + ')'"
          ></div>
        </div>
      </a>

      <a *ngIf="!user" (click)="login()" class="item item-loggin gap-1">
        <svg-icon
          src="/assets/images/nav/menu-login.svg"
          [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"
          class="item-icon -ml-2 mt-8"
        ></svg-icon>
        <span class="item-title">{{
          'SIDENAV-MENU.LOGIN' | translate: locale.language
        }}</span>
      </a>
      <a
        *ngIf="!!user"
        (click)="extraSidebarVisible = !extraSidebarVisible"
        class="item progfileItem gap-1"
        [class.active]="extraSidebarVisible || isAccountRoute"
        [class.expanded]="extraSidebarVisible"
      >
        <svg-icon
          class="ignoreFill item-icon"
          src="/assets/images/nav/menu-account.svg"
          [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
        ></svg-icon>
        <span class="item-title capitalize">{{
          'ACCOUNT.MENU.Account' | translate: locale.language
        }}</span>

        <img
          src="./assets/images/menu/arrow-downThick.svg"
          alt="Arrow"
          class="profileArrow"
        />
      </a>

      <div
        class="dividerNew mt-[10px] mb-[10px] h-[1px] border-b border-info-content opacity-50 mx-4"
      ></div>

      <a
        *ngIf="featurePageSubscription && isProUser"
        [routerLink]="
          !!user ? '/account/dashboard/subscription' : '/info/pricing'
        "
        class="item gap-1"
      >
        <svg-icon
          src="/assets/images/navigation/Premium.svg"
          [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          class="item-icon"
        ></svg-icon>
        <span class="item-title">{{
          'ACCOUNT.MENU.Subscription' | translate: locale.language
        }}</span>
      </a>

      <a
        [routerLink]="['/gingrs']"
        class="item gap-1"
        routerLinkActive="active"
      >
        <svg-icon
          src="/assets/images/nav/menu-escorts.svg"
          [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          class="item-icon"
        ></svg-icon>
        <span class="item-title capitalize">{{
          'ACCOUNT.MENU.Escorts' | translate: locale.language
        }}</span>
      </a>
      <ng-container *ngIf="false">
        <a
          [routerLink]="['/establishments']"
          class="item gap-1"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/clubs-new.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
            class="item-icon"
          ></svg-icon>
          <span class="item-title capitalize">{{
            'ACCOUNT.MENU.Clubs' | translate: locale.language
          }}</span>
        </a>
        <a
          [routerLink]="['/agencies']"
          class="item gap-1"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/ageencies-new.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
            class="item-icon"
          ></svg-icon>
          <span class="item-title capitalize">{{
            'ACCOUNT.MENU.Agencies' | translate: locale.language
          }}</span>
        </a></ng-container
      >

      <div
        class="dividerNew mt-[10px] mb-[10px] h-[1px] border-b border-info-content opacity-50 mx-4"
      ></div>

      <a
        (click)="openIntercomSupport()"
        class="item gap-1"
        routerLinkActive="active"
      >
        <svg-icon
          src="/assets/images/nav/menu-support.svg"
          [svgStyle]="{ 'width.px': 30, 'height.px': 30 }"
          class="item-icon"
        ></svg-icon>
        <span class="item-title capitalize">{{
          'SIDENAV-MENU.Support' | translate: locale.language
        }}</span>
      </a>
      <a [routerLink]="['/info']" class="item gap-1" routerLinkActive="active">
        <svg-icon
          src="/assets/images/nav/menu-info.svg"
          [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          class="item-icon"
        ></svg-icon>
        <span class="item-title">{{
          'SIDENAV-MENU.INFO' | translate: locale.language
        }}</span>
      </a>
      <a [routerLink]="['/welcome']" class="item gap-1">
        <svg-icon
          src="/assets/images/nav/menu-how-it-works.svg"
          [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          class="item-icon"
        ></svg-icon>
        <span class="item-title capitalize">{{
          'SIDENAV-MENU.How it works' | translate: locale.language
        }}</span>
      </a>
    </div>

    <a
      *ngIf="!!user"
      (click)="logoutEvent.emit(); extraSidebarVisible = false"
      class="logoutSection item gap-1"
    >
      <svg-icon
        src="/assets/images/nav/menu-logout.svg"
        [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
        class="item-icon ignoreFill"
      ></svg-icon>
      <span class="item-title">{{
        'SIDENAV-MENU.LOGOUT' | translate: locale.language
      }}</span>
    </a>
  </div>
</div>

<div class="sidebarMore" [hidden]="!extraSidebarVisible">
  <div fxLayout="column">
    <p class="build-id font-[10px]" *ngIf="showBuildId">
      build: {{ releaseId }}
    </p>

    <ng-container *ngIf="!!user">
      <div class="profileContentBottom">
        <p class="profileContentBottom--title text-primary capitalize">
          {{ 'ACCOUNT.MENU.Account' | translate: locale.language }}
        </p>

        <a
          [routerLink]="['/account', 'referral']"
          [class.open]="referralExpanded"
          class="sideMenu-item pl-[31px] py-2 mt-[2px] !min-h-[60px]"
          (click)="referralExpanded = !referralExpanded"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-referral.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{
            'ACCOUNT.MENU.Referral' | translate: locale.language
          }}</span>
        </a>

        <div
          class="dividerNew mt-[10px] mb-[10px] h-[1px] border-b border-info-content opacity-50 mx-4"
        ></div>

        <a
          [routerLink]="['/account', 'profile']"
          [class.open]="profileExpanded"
          class="sideMenu-item pr-5 pl-[31px]"
          (click)="profileExpanded = !profileExpanded"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-profile.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{ 'ACCOUNT.MENU.Profile' | translate: locale.language }}</span>
          <a
            class="ml-auto submenuArrow"
            *ngIf="
              baseRole === userAvailableRoles.gingr.baseRoleName ||
              baseRole === userAvailableRoles.agency.baseRoleName ||
              baseRole === userAvailableRoles.brothels.baseRoleName
            "
          >
            <img src="./assets/images/menu/arrow-downThick.svg" alt="Arrow" />
          </a>
        </a>

        <ng-container *ngIf="profileExpanded">
          <ng-container
            *ngIf="
              baseRole === userAvailableRoles.gingr.baseRoleName ||
              baseRole === userAvailableRoles.agency.baseRoleName ||
              baseRole === userAvailableRoles.brothels.baseRoleName
            "
          >
            <div class="settingsContainer">
              <div class="settingsContainer"></div>
              <a
                [routerLink]="['/account', 'profile', 'account-details']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Account Details' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'languages']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Nationality & Languages' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'location']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Location' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'media']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Photos & Videos' | translate: locale.language
                }}</span>
              </a>
            </div>
          </ng-container>
          <ng-container
            *ngIf="baseRole === userAvailableRoles.gingr.baseRoleName"
          >
            <div class="settingsContainer">
              <a
                [routerLink]="['/account', 'profile', 'pricing']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Pricing' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'services']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Services' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'availability']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Availability' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'personal-details']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Personal Details' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'verification']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Verification' | translate: locale.language
                }}</span>
              </a>
            </div>
          </ng-container>
          <div class="settingsContainer">
            <a
              *ngIf="baseRole === userAvailableRoles.brothels.baseRoleName"
              [routerLink]="['/account', 'profile', 'facilities']"
              class="sideMenu-item pr-5 pl-[31px]"
              routerLinkActive="active"
            >
              <span>{{
                'ACCOUNT.Facilities' | translate: locale.language
              }}</span>
            </a>
          </div>

          <ng-container
            *ngIf="
              baseRole === userAvailableRoles.agency.baseRoleName ||
              baseRole === userAvailableRoles.brothels.baseRoleName
            "
          >
            <div class="settingsContainer">
              <a
                [routerLink]="['/account', 'profile', 'opening-hours']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.MENU.Opening Hours' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'extra-details']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.Extra details' | translate: locale.language
                }}</span>
              </a>
              <a
                [routerLink]="['/account', 'profile', 'default-gingr-settings']"
                class="sideMenu-item pr-5 pl-[31px]"
                routerLinkActive="active"
              >
                <span>{{
                  'ACCOUNT.MENU.Default Gingr Settings'
                    | translate: locale.language
                }}</span>
              </a>
            </div>
          </ng-container>
        </ng-container>

        <a
          [routerLink]="['/wallet/overview']"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-credit-balance.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span class="first-letter:capitalize">{{
            'CREDIT.wallet' | translate: locale.language
          }}</span>
        </a>

        <a
          [routerLink]="['/account', 'bookings']"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-bookings.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{
            'ACCOUNT.MENU.Bookings' | translate: locale.language
          }}</span>
        </a>

        <a
          [routerLink]="['/account', 'reviews']"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
          *ngIf="
            user.role === userRoles.SERVICE_PROVIDER_BASIC ||
            user.role === userRoles.SERVICE_PROVIDER_PRO
          "
        >
          <svg-icon
            src="/assets/images/nav/menu-reviews.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{ 'ACCOUNT.MENU.Reviews' | translate: locale.language }}</span>
        </a>

        <a
          [routerLink]="['/account', 'dashboard', 'notifications']"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-notifications.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{
            'SIDENAV-MENU.Notifications' | translate: locale.language
          }}</span>
        </a>

        <a
          *ngIf="baseRole === userAvailableRoles.gingr.baseRoleName"
          [routerLink]="['/workplace']"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-profile.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{
            'ACCOUNT.MENU.Workplace' | translate: locale.language
          }}</span>
        </a>

        <a
          *ngIf="
            baseRole === userAvailableRoles.brothels.baseRoleName ||
            baseRole === userAvailableRoles.agency.baseRoleName
          "
          [routerLink]="['/manage-gingrs']"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-profile.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{
            'ACCOUNT.MENU.Manage Gingrs' | translate: locale.language
          }}</span>
        </a>

        <a
          *ngIf="featureHasGGCoin"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-profile.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{
            'SIDENAV-MENU.GGC Tokens' | translate: locale.language
          }}</span>
        </a>
        <a
          [routerLink]="['/chat']"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/svg-icons/chat/chat.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{ 'SIDENAV-MENU.Chat' | translate: locale.language }}</span>
        </a>
        <a
          [routerLink]="['/account', 'settings']"
          [class.open]="settingsExpanded"
          class="sideMenu-item pr-5 pl-[31px]"
          routerLinkActive="active"
        >
          <svg-icon
            src="/assets/images/nav/menu-settings.svg"
            [svgStyle]="{ 'width.px': 28, 'height.px': 28 }"
          ></svg-icon>
          <span>{{
            'ACCOUNT.MENU.Settings' | translate: locale.language
          }}</span>
        </a>
      </div>
    </ng-container>
  </div>
</div>
