import {
  Component,
  effect,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  getCurrentUser,
  getCurrentUserPreference,
  getKycVerification,
  getUserStdTest,
  hasNotificationSet,
  isUserClient,
} from '@app/shared/reducers/user.selectors';
import {
  L10N_LOCALE,
  L10nTranslatePipe,
  L10nTranslationService,
} from 'angular-l10n';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { ProfileTileModel } from '@app/account/containers/profile-v2/models/profile-tile.model';
import { ProfileTileComponent } from '@app/account/containers/profile-v2/components/profile-tile/profile-tile.component';
import {
  AccountService,
  InformationFillingService,
} from '@app/account/services';
import { UserRoles } from '@app/shared/models/enum/userroles';
import { ProfileApprovedStatus } from '@app/shared/models/current-user';
import {
  getKycVerificationAction,
  getUserStdTestAction,
  setBrowseUser,
  setCurrentUserAction,
  setUserEmailVerifiedAction,
} from '@app/shared/actions/shared.actions';
import { NotificationService, UserService } from '@app/shared/services';
import { SvgIconComponent } from 'angular-svg-icon';
import { DataSpikeVerificationStatus } from '@app/shared/models/data-spike/data-spike-verification.model';
import { BasicUserDetails } from '@app/account/models/basic-user-details';
import { MissingMandatoryProgress } from '@app/account/containers/profile-v2/components/banners/missing-mandatory-progress/missing-mandatory-progress.component';
import { MandatoryCompleteNotLive } from '@app/account/containers/profile-v2/components/banners/mandatory-complete-not-live/mandatory-complete-not-live.component';
import { LiveAccountBanner } from '@app/account/containers/profile-v2/components/banners/live-account/live-account-banner.component';
import { UserStdApprovalStatus } from '@app/account/containers/profile-v2/models/user-std.model';
import * as dayjs from 'dayjs';
import { take } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'gingr-profile-v2',
  standalone: true,
  imports: [
    CommonModule,
    L10nTranslatePipe,
    RouterOutlet,
    ProfileTileComponent,
    RouterLink,
    SvgIconComponent,
    MissingMandatoryProgress,
    MandatoryCompleteNotLive,
    LiveAccountBanner,
  ],
  templateUrl: './profile-v2.component.html',
  styleUrls: ['./profile-v2.component.scss'],
})
export class ProfileV2Component implements OnInit, OnDestroy {
  static BrowseEscortKey = 'browseEscort';
  static BrowseEscortTimestampKey = 'browseEscortTimestamp';

  private readonly store = inject(Store);
  private readonly translation = inject(L10nTranslationService);
  private readonly accountService = inject(AccountService);
  readonly notificationService = inject(NotificationService);
  readonly locale = inject(L10N_LOCALE);
  readonly router = inject(Router);
  private readonly auth = inject(AuthService);
  readonly userService = inject(UserService);

  readonly isClient = this.store.selectSignal(isUserClient);
  readonly user = this.store.selectSignal(getCurrentUser);
  readonly hasNotificationsSet = this.store.selectSignal(hasNotificationSet);
  readonly userPreference = this.store.selectSignal(getCurrentUserPreference);
  readonly kycVerification = this.store.selectSignal(getKycVerification);
  readonly stdTest = this.store.selectSignal(getUserStdTest);

  kycTmr;
  mandatoryTiles: ProfileTileModel[] = [];
  optionalTiles: ProfileTileModel[] = [];
  windowWidth = window.innerWidth;
  mandatoryProgress = 0;
  optionalProgress = 0;
  userRoleTypes = UserRoles;
  profileApprovedStatusTypes = ProfileApprovedStatus;
  isRequesting = false;
  browseUserDetails: BasicUserDetails;

  constructor() {
    effect(() => {
      if (this.user()) {
        this.setBrowseEscortValue();
        if (!this.user().emailVerification) {
          this.checkEmailIsVerified();
        }
      }

      if (this.isClient() !== undefined && this.user()) {
        const kycTile = {
          title: this.translation.translate('ACCOUNT.KYC.verify yourself'),
          fillPercent:
            this.kycVerification()?.status ===
              DataSpikeVerificationStatus.Verified ||
            this.kycVerification()?.status ===
              DataSpikeVerificationStatus.Expired ||
            this.kycVerification()?.status ===
              DataSpikeVerificationStatus.Failed
              ? 100
              : this.kycVerification()?.status ===
                  DataSpikeVerificationStatus.InProgress
                ? 99
                : this.kycVerification()?.status ===
                    DataSpikeVerificationStatus.Pending
                  ? 50
                  : 0,
          mainSvgIcon: 'kyc',
          route: 'kyc',
          stripeColor:
            this.kycVerification()?.status ===
              DataSpikeVerificationStatus.Expired ||
            this.kycVerification()?.status ===
              DataSpikeVerificationStatus.Failed
              ? '#FF0015'
              : undefined,
          statusSvgIcon:
            this.kycVerification()?.status ===
            DataSpikeVerificationStatus.Expired
              ? {
                  url: '/assets/svg-icons/common/status-pending-circle.svg',
                  color: '#FF0015',
                }
              : this.kycVerification()?.status ===
                  DataSpikeVerificationStatus.Failed
                ? {
                    url: '/assets/svg-icons/common/status-error-circle.svg',
                    color: '#FF0015',
                  }
                : undefined,
          badge:
            this.kycVerification()?.status ===
            DataSpikeVerificationStatus.Expired
              ? 'ACCOUNT.KYC.expired'
              : this.kycVerification()?.status ===
                  DataSpikeVerificationStatus.Failed
                ? 'ACCOUNT.KYC.failed'
                : this.kycVerification()?.status ===
                    DataSpikeVerificationStatus.InProgress
                  ? 'ACCOUNT.KYC.processing'
                  : undefined,
        };

        this.mandatoryTiles = [
          {
            title: this.translation.translate('ACCOUNT.Account Details'),
            fillPercent: this.isClient()
              ? InformationFillingService.calculateClientAccountDetails(
                  this.user()
                )
              : InformationFillingService.calculateGingrAccountDetails(
                  this.user()
                ),
            mainSvgIcon: 'account-details',
            route: 'account',
          },
          {
            title: this.translation.translate('COMMON.PHONE_VERIFICATION'),
            fillPercent: this.user().mobile ? 100 : 0,
            mainSvgIcon: 'phone-verification',
            route: 'phone',
          },
        ];

        if (!this.isClient()) {
          this.mandatoryTiles.push(
            ...[
              {
                title: this.translation.translate(
                  'ACCOUNT.Nationality & Languages'
                ),
                fillPercent: InformationFillingService.calculateLanguagesOrigin(
                  this.user()
                ),
                mainSvgIcon: 'nationality-languages',
                route: 'languages-origin',
              },
              {
                title: this.translation.translate('BOOKINGS.Meeting Point'),
                fillPercent: InformationFillingService.calculateMeetingPoint(
                  this.user()
                ),
                mainSvgIcon: 'meeting-point',
                route: 'meeting-point',
              },
              {
                title: this.translation.translate('ACCOUNT.Pricing'),
                fillPercent: InformationFillingService.calculatePricing(
                  this.user()
                ),
                mainSvgIcon: 'pricing',
                route: 'pricing',
              },
              {
                title: this.translation.translate('ACCOUNT.Photo & Video'),
                fillPercent: InformationFillingService.calculateMedia(
                  this.user(),
                  this.isClient()
                ),
                mainSvgIcon: 'photos-videos',
                route: 'media',
              },
              {
                title: this.translation.translate('ACCOUNT.Services'),
                fillPercent: InformationFillingService.calculateServices(
                  this.user()
                ),
                mainSvgIcon: 'services',
                route: 'services',
              },
              {
                title: this.translation.translate('ACCOUNT.Working hours'),
                fillPercent: InformationFillingService.calculateAvailability(
                  this.user()
                ),
                mainSvgIcon: 'availability',
                route: 'availability',
              },
              {
                title: this.translation.translate(
                  'ACCOUNT.NOTIFICATIONS.Notifications'
                ),
                fillPercent:
                  this.hasNotificationsSet() ||
                  this.userPreference()?.doNotWantNotifications
                    ? 100
                    : 0,
                mainSvgIcon: 'notifications',
                route: 'notification',
              },
              kycTile,
            ]
          );
        }

        this.optionalTiles = [
          {
            title: this.translation.translate('ACCOUNT.Personal Details'),
            fillPercent: this.isClient()
              ? InformationFillingService.calculateClientPersonalDetails(
                  this.user()
                )
              : InformationFillingService.calculateGingrPersonalDetails(
                  this.user()
                ),
            mainSvgIcon: 'personal-details',
            route: 'personal',
          },
          {
            title: this.translation.translate('ACCOUNT.STD.STD tests'),
            fillPercent: InformationFillingService.calculateStd(this.stdTest()),
            stripeColor:
              this.stdTest()?.approvalStatus ===
                UserStdApprovalStatus.Rejected ||
              (this.stdTest()?.approvalStatus ===
                UserStdApprovalStatus.Approved &&
                dayjs(this.stdTest().validTo).isBefore(dayjs()))
                ? '#FF0015'
                : undefined,
            statusSvgIcon:
              this.stdTest()?.approvalStatus ===
                UserStdApprovalStatus.Approved &&
              dayjs(this.stdTest().validTo).isBefore(dayjs())
                ? {
                    url: '/assets/svg-icons/common/status-pending-circle.svg',
                    color: '#FF0015',
                  }
                : this.stdTest()?.approvalStatus ===
                    UserStdApprovalStatus.Rejected
                  ? {
                      url: '/assets/svg-icons/common/status-error-circle.svg',
                      color: '#FF0015',
                    }
                  : undefined,
            mainSvgIcon: 'std-tests',
            route: 'std',
          },
        ];

        if (this.isClient()) {
          this.optionalTiles.push(
            {
              title: this.translation.translate('ACCOUNT.photos'),
              fillPercent: InformationFillingService.calculateMedia(
                this.user(),
                this.isClient()
              ),
              mainSvgIcon: 'photos-videos',
              route: 'media',
            },
            {
              title: this.translation.translate(
                'ACCOUNT.NOTIFICATIONS.Notifications'
              ),
              fillPercent: this.hasNotificationsSet() ? 100 : 0,
              mainSvgIcon: 'notifications',
              route: 'notification',
            },
            kycTile
          );
        }

        if (!this.isClient()) {
          this.optionalTiles.push(
            {
              title: this.translation.translate('ACCOUNT.AGE.age verification'),
              fillPercent: InformationFillingService.calculateVerifiedAge(
                this.user()
              ),
              mainSvgIcon: 'age',
              route: 'age',
            },
            {
              title: this.translation.translate('ACCOUNT.VOICE.Title'),
              fillPercent: InformationFillingService.calculateVoice(
                this.user()
              ),
              mainSvgIcon: 'voice',
              route: 'voice',
            }
          );
        }

        this.mandatoryProgress = this.isClient()
          ? InformationFillingService.getMandatoryFieldsFillPercentForClient(
              this.user()
            )
          : InformationFillingService.getMandatoryFieldsFillPercentForGingr(
              this.user(),
              this.hasNotificationsSet() ||
                this.userPreference()?.doNotWantNotifications,
              this.kycVerification()?.status ===
                DataSpikeVerificationStatus.Verified
            );
        this.optionalProgress = this.isClient()
          ? InformationFillingService.getOptionalFieldsFillPercentForClient(
              this.user(),
              !!this.hasNotificationsSet(),
              this.kycVerification()?.status ===
                DataSpikeVerificationStatus.Verified
            )
          : InformationFillingService.getOptionalFieldsFillPercentForGingr(
              this.user(),
              this.stdTest()
            );
      }
    });

    effect(() => {
      if (this.kycVerification()) {
        if (
          this.kycVerification().status !== DataSpikeVerificationStatus.Verified
        ) {
          if (this.kycTmr) {
            clearTimeout(this.kycTmr);
          }
          this.kycTmr = setTimeout(
            () => this.store.dispatch(getKycVerificationAction()),
            10000
          );
        }
      }
    });
  }

  checkEmailIsVerified() {
    this.auth
      .getAccessTokenSilently({ cacheMode: 'off' })
      .pipe(take(1))
      .subscribe(() => {
        this.userService.checkVerificationEmail().subscribe((data) => {
          if (data) {
            this.store.dispatch(setUserEmailVerifiedAction({ verified: true }));
          }
        });
      });
  }

  setBrowseEscortValue() {
    const browseEscort = localStorage.getItem(
      ProfileV2Component.BrowseEscortKey
    );
    if (
      browseEscort &&
      this.user()?.profileApprovedStatus !== ProfileApprovedStatus.APPROVED
    ) {
      const browseEscortTimestamp = localStorage.getItem(
        ProfileV2Component.BrowseEscortTimestampKey
      );
      // Difference in milliseconds
      const diffInMilliseconds = Date.now() - +browseEscortTimestamp;
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

      if (diffInHours <= 24) {
        this.accountService
          .getBasicUserDetails(
            +localStorage.getItem(ProfileV2Component.BrowseEscortKey)
          )
          .subscribe((result) => {
            this.browseUserDetails = result;
            this.browseUserDetails.id = +localStorage.getItem(
              ProfileV2Component.BrowseEscortKey
            );
            this.store.dispatch(
              setBrowseUser({
                data: {
                  show: true,
                  displayName: this.browseUserDetails.displayName,
                  id: +localStorage.getItem(ProfileV2Component.BrowseEscortKey),
                  avatarUrl: this.browseUserDetails.avatarUrl,
                },
              })
            );
          });
      } else if (localStorage.getItem(ProfileV2Component.BrowseEscortKey)) {
        localStorage.removeItem(ProfileV2Component.BrowseEscortKey);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
  }

  ngOnInit() {
    setTimeout(() => {
      if (!this.kycVerification()) {
        this.store.dispatch(getKycVerificationAction());
      }

      if (!this.stdTest()) {
        this.store.dispatch(getUserStdTestAction());
      }
    }, 100);
  }

  ngOnDestroy() {
    if (this.kycTmr) {
      clearTimeout(this.kycTmr);
    }
    if (localStorage.getItem(ProfileV2Component.BrowseEscortKey)) {
      localStorage.removeItem(ProfileV2Component.BrowseEscortKey);
    }
    if (localStorage.getItem(ProfileV2Component.BrowseEscortTimestampKey)) {
      localStorage.removeItem(ProfileV2Component.BrowseEscortTimestampKey);
    }
  }

  publishProfile() {
    if (this.isRequesting) {
      return;
    }
    this.isRequesting = true;

    this.accountService.goLive().subscribe({
      next: (data) => {
        this.notificationService.success(
          null,
          this.translation.translate('ACCOUNT.profile pending to be published')
        );
        this.isRequesting = false;
        this.store.dispatch(setCurrentUserAction({ data: data }));
      },
      error: () => {
        this.notificationService.error(
          null,
          this.translation.translate('ACCOUNT.DETAILS.Account updated error')
        );
        this.isRequesting = false;
      },
    });
  }

  goToProfilePreview(isGingr: boolean) {
    if (this.mandatoryProgress !== 100) {
      return;
    }
    if (isGingr) {
      this.router.navigate(
        [`/gingrs/profile/${this.user().id}/preview/overview`],
        {
          state: {
            urlBack: '/account/profile',
          },
        }
      );
    } else {
      this.router.navigate([`/clients/profile/${this.user().id}`], {
        state: {
          urlBack: '/account/profile',
          preview: true,
        },
      });
    }
  }
}
