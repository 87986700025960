import { UserRoles } from '@app/shared/models/enum/userroles';
import { Pricing } from '@app/shared/models/pricing';
import { Injectable } from '@angular/core';
import { UserType } from '@app/shared/models/user-type';

@Injectable({
  providedIn: 'root',
})
export class UserLocation {
  address: string;
  formatted_address: string;
  longitude: number;
  latitude: number;
  city: string;
  country: string;
  isArea: number;
  have_working_address: boolean;
  hidden: boolean;
  noWorkingAddress: boolean;
  postalCode: string;
  countryname?: string;
}

export class UserAccountMarker {
  address: string;
  formatted_address: string;
  longitude: number;
  latitude: number;
  city: string;
  country: string;
  icon: any;
}

export interface EstablishmentConnection {
  establishmentId: number;
  isActive: boolean;
  isDefaultAvailability: boolean;
  isDefaultPricing: boolean;
  isDefaultServices: boolean;
  isManaged: boolean;
  status: EstablishmentConnectionStatus;
}

export enum EstablishmentConnectionStatus {
  FREE = 'FREE',
  YOURS = 'YOURS',
}

interface UserRole {
  name: string;
  baseRole: number;
  baseRoleName: string;
  roles: Array<number>;
}

export class User {
  id: number;
  idpid: string;
  bio: string;
  cover_image: string;
  currency: string;
  email?: string;
  image: string;
  avatar_image: number;
  activeEstablishment?: EstablishmentConnection;
  lastseen: object;
  favourites: object;
  location: UserLocation;
  language: number;
  locationStored: UserLocation;
  name: string;
  new: boolean;
  surname: string;
  banner_image: string;
  media: Array<any>;
  prefix_phone: any;
  prefix_mobile: any;
  phone: string;
  mobile: string;
  review: Array<any>;
  role: number;
  roleId: string;
  token: string;
  user: object;
  isProfileFulfilled?: boolean;
  username: string;
  displayName: string;
  user_privacy: UserPrivacy;
  disable_mode: number;
  // extras
  emailverification: number;
  smsverification: number;
  phoneverification: number;
  addressverification: number;
  verified: number;
  sex: number;
  sexuality: number;
  gender: number;
  hear: string;
  services: any;
  identify: UserIdentify;
  identify_photo: UserIdentify;
  referral: string;
  profile: UserProfile;
  pricing: Pricing;
  speaks: Array<any>;
  blockedCountries: Array<any>;
  metadata: string;
  busy: number;
  vacation: number;
  endVacationDate: string;
  hide_in_home: number;
  company?: UserType;
  live: number;
  profile_approved: number;
  premium: number;
  distance_in_km: string;
  establishment: any;
  slug: string;
  filename: string;
  lang_iso: string;
  cache_control: number;
  avatar: string;
}

export class UserProfile {
  user_id: number;
  talia: number;
  dob: string;
  year_of_birth: string;
  notes: string;
  height: number;
  weight: number;
  brest: number;
  stomach: number;
  waist: number;
  penissize: number;
  hear: number;
  hear_text: string;
  bust: string;
  bodytype: Array<UserBody>;
  hair: number;
  origin: number;
  nationality: string;
  what_are_you: string;
  description: string;
  drinks: string;
  occupation: string;
  dressstyle: string;
  food: string;
  interests: string;
  eye: number;
  piercingFace: number;
  piercingIntimate: number;
  tattoo: number;
  breast_implant: number;
  smoke: number;
  alcohol: number;
  meta: string;
  girls: number;
  contact: string;
  contactEmail: string;
  web: string;
  currency: string;
}

export interface UserTypeRole {
  isGuest?: boolean;
  isClient?: boolean;
  isGingr?: boolean;
  isEstablishment?: boolean;
  isAgency?: boolean;
}

export const UserAvailableRoles = {
  client: <UserRole>{
    name: 'Client',
    roles: [UserRoles.CLIENT_BASIC, UserRoles.CLIENT_PRO],
    baseRole: UserRoles.CLIENT_BASIC,
    baseRoleName: 'client',
  },
  gingr: <UserRole>{
    name: 'Gingr',
    roles: [UserRoles.SERVICE_PROVIDER_BASIC, UserRoles.SERVICE_PROVIDER_PRO],
    baseRole: UserRoles.SERVICE_PROVIDER_BASIC,
    baseRoleName: 'gingr',
  },
  brothels: <UserRole>{
    name: 'Brothels',
    roles: [UserRoles.ESTABLISHMENT_BASIC, UserRoles.ESTABLISHMENT_PRO],
    baseRole: UserRoles.ESTABLISHMENT_BASIC,
    baseRoleName: 'brothels',
  },
  agency: <UserRole>{
    name: 'Agency',
    roles: [UserRoles.AGENCY_BASIC, UserRoles.AGENCY_PRO],
    baseRole: UserRoles.AGENCY_BASIC,
    baseRoleName: 'agency',
  },
  admin: <UserRole>{
    name: 'Admin',
    roles: [UserRoles.ADMIN],
    baseRole: UserRoles.ADMIN,
    baseRoleName: 'admin',
  },
  ico: <UserRole>{
    name: 'ICO',
    roles: [UserRoles.ICO],
    baseRole: UserRoles.ICO,
    baseRoleName: 'ico',
  },
  press: <UserRole>{
    name: 'Press client',
    roles: [UserRoles.CLIENT_PRESS],
    baseRole: UserRoles.CLIENT_PRESS,
    baseRoleName: 'press',
  },
};

export class UserSex {
  id: number;
  name: string;
  sex_name: string;
}
export class UserOrigin {
  id: number;
  name: string;
  origin_name: string;
}

export class UserSexuality {
  id: number;
  name: string;
  sexuality_name: string;
}

export class UserGenitaliaArea {
  id: number;
  name: string;
  talia_name: string;
}

export class UserHear {
  id: number;
  name: string;
  userhear_name: string;
}

export class UserHairColor {
  id: number;
  name: string;
  haircolors_name: string;
}

export class UserEyeColor {
  id: number;
  color: string;
  color_name: string;
}

export class UserBody {
  id: number;
  name: string;
  bodytypes_name: string;
  group: string;
}

export class UserBodyArt {
  id: number;
  name: string;
  bodyart_name: string;
}

export class UserPhonePrefix {
  prefix: number;
  code: string;
  nationality: string;
  countryName: string;
}

export class UserServiceItem {
  id: number;
  name: string;
  description: string;
  more_services: number;
  ambit: string;
  activity: string;
  excerpt: string;
  selected: boolean;
  price: number;
}
export class EstablishmentServiceItem {
  id: number;
  name: string;
  service_name: string;
  excerpt: string;
  description: string;
  selected: boolean;
}

export class UserServiceActivityType {
  name: string;
  value: number;
}

export const UserAvailableSex: any = {
  female: <number>0,
  male: <number>1,
  trans: <number>2,
};

export const UserAvailableServiceActivityTypes: any = {
  active: <UserServiceActivityType>{ name: 'ACTIVE', value: 1 },
  passive: <UserServiceActivityType>{ name: 'PASSIVE', value: 0 },
};

export class UserIdentify {
  approved: number;
  status: UserIdentifyStatus;
  filename?: string;
}

export enum UserIdentifyStatus {
  PENDING = 'PENDING',
}

export class UserPrivacy {
  sms: Array<any>;
  email: Array<any>;
  mobile_visibility: Array<any>;
  phone_visibility: Array<any>;
}

export class UserLoginResult {
  token: string;
  user: User;
}

export class UserStats {
  views: number;
  phone_views: number;
  messages: number;
  bookingsTotal: number;
  hours: number;
  earnings: number;
}
