export interface TransactionModel {
  id: number;
  amount: number;
  deducted: boolean;
  dateCreated: string;
  transactionType: TransactionType;
  paymentMethod?: PaymentMethod;
  transactionStatus?: TransactionStatus;
  icon?: string;
  invoiceDownloadLink: string;
  invoiceStatus: InvoiceStatus;
  lateFeesAmount?: number;
}

export enum TransactionType {
  Welcome = 'Welcome',
  Support = 'Support',
  TopUp = 'TopUp',
  ServiceFee = 'ServiceFee',
  ServiceFeeRefund = 'ServiceFeeRefund',
  Referral = 'Referral',
  BalanceSettlement = 'BalanceSettlement',
  Payout = 'Payout',
  BookingPayment = 'BookingPayment',
  CancellationCompensation = 'CancellationCompensation',
  BookingCancellation = 'BookingCancellation',
}

export enum PaymentMethod {
  BankTransfer = 'BankTransfer',
  PaymentSlip = 'PaymentSlip',
  Cash = 'Cash',
  Card = 'Card',
  Invoice = 'Invoice',
  CreditInvoice = 'CreditInvoice',
}

export enum TransactionStatus {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Rejected = 'Rejected',
  PendingEscort = 'PendingEscort',
}

export enum InvoiceStatus {
  Draft = 7,
  Pending = 8,
  Paid = 9,
  Partial = 16,
  Canceled = 19,
  Unpaid = 31,
}

export function GetTransactionIconByType(
  transactionType: TransactionType
): string {
  switch (transactionType) {
    case TransactionType.Payout:
      return '/assets/svg-icons/credit/icon-payout.svg';
    case TransactionType.Referral:
      return '/assets/svg-icons/credit/tx-referral-payment.svg';
    case TransactionType.ServiceFee:
      return '/assets/svg-icons/credit/tx-gingr-service-fee.svg';
    case TransactionType.ServiceFeeRefund:
      return '/assets/svg-icons/credit/tx-gingr-service-fee-refund.svg';
    case TransactionType.CancellationCompensation:
      return '/assets/svg-icons/credit/tx-compensation-refund.svg';
    case TransactionType.BookingCancellation:
      return '/assets/svg-icons/credit/tx-booking-cancellation.svg';
    case TransactionType.BookingPayment:
      return '/assets/svg-icons/credit/tx-booking-payment.svg';
    case TransactionType.BalanceSettlement:
      return '/assets/svg-icons/credit/tx-balance-settlement.svg';
    case TransactionType.Support:
      return '/assets/svg-icons/credit/tx-gingr-support.svg';
    case TransactionType.TopUp:
      return '/assets/svg-icons/credit/tx-top-up.svg';
    case TransactionType.Welcome:
      return '/assets/svg-icons/credit/tx-welcome-money.svg';
  }
}
