import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'gingr-auth-callback',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent {}
