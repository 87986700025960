import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { SvgIconComponent } from 'angular-svg-icon';
import { BasicUserDetails } from '@app/account/models/basic-user-details';
import { PaymentStatus } from '@app/shared/models/current-user';

@Component({
  selector: 'live-account-banner',
  standalone: true,
  imports: [CommonModule, L10nTranslatePipe, RouterLink, SvgIconComponent],
  templateUrl: './live-account-banner.component.html',
  styleUrls: ['./live-account-banner.component.scss'],
})
export class LiveAccountBanner {
  readonly locale = inject(L10N_LOCALE);
  @Input() isClient: boolean;
  @Input() creditBalance: number;
  @Input() optionalProgress: number;
  @Input() browseUserDetails: BasicUserDetails;
  @Input() paymentStatus: PaymentStatus = PaymentStatus.Active;
  paymentStatusEnum = PaymentStatus;

  alertLimit = -800;
  inactiveLimit = -1000;
}
