<div class="customMenu" *ngIf="showCustomMenu">
  <div class="page-center custom-page-center">
    <div class="menu-wrapper">
      <div class="section">
        <a [routerLink]="['/gingrs']">
          <img src="/assets/homepage/mainLogo.svg" alt="" class="main-logo" />
        </a>

        <img src="/assets/homepage/ggcoinLogo.svg" alt="" class="hidden-xs" />
      </div>
      <gingr-menu
        [attr.baseLinks]="menuLinks"
        [attr.platform]="'hookup'"
      ></gingr-menu>
    </div>
  </div>
</div>

<div
  class="main-wrapper"
  id="main-wrapper"
  #wrapper
  [class.instaSection]="
    isInstaSection || showCustomMenu || isProfilePreview || walletSucces
  "
  [class.noTopAttr]="showCustomMenu"
  [style.top.px]="64 + headerTopPx"
>
  <gingr-header
    *ngIf="
      !(isProfilePreview && screenWidth < 769) &&
      !(isBookingDetailsSection && screenWidth < 769) &&
      !(isInstaSection && screenWidth < 769) &&
      !(isAppointmentSection && screenWidth < 1280) &&
      !(isAccountProfileTile && screenWidth < 1280) &&
      !(isCreditSubSection && screenWidth < 1280) &&
      !(isSettingsTile && screenWidth < 1280) &&
      !(isReplyReviewPage && screenWidth < 1280) &&
      !showCustomMenu &&
      !(isClientProfilePage && screenWidth < 769)
    "
    (filterToggle)="filterToggle()"
    (navToggle)="mobileMenuOpen = !mobileMenuOpen"
    [isMenuClosed]="!mobileMenuOpen"
    [style.top.px]="headerTopPx"
  >
  </gingr-header>

  <div class="app-wrapper" [class.no-scroll]="showFilter">
    <div fxLayout="row">
      <gingr-sidenav-desktop
        *ngIf="
          ((screenWidth > 768 &&
            !isAccountProfile &&
            !isProfileOverview &&
            !isAppointmentSection) ||
            screenWidth >= 1280) &&
          !isInstaSection
        "
        [user]="currentUser"
        [avatar]="avatar"
        [isProUser]="isProUser"
        [featurePageSubscription]="featurePageSubscription"
        [baseRole]="baseRole"
        [featureHasGGCoin]="featureHasGGCoin"
        [showBuildId]="showBuildId"
        [releaseId]="releaseId"
        (logoutEvent)="logout()"
      ></gingr-sidenav-desktop>
      <gingr-sidenav-mobile
        *ngIf="
          (screenWidth <= 768 ||
            ((isAccountProfile || isProfileOverview || isAppointmentSection) &&
              screenWidth < 1280)) &&
          mobileMenuOpen
        "
        [user]="currentUser"
        [avatar]="avatar"
        [isProUser]="isProUser"
        [featurePageSubscription]="featurePageSubscription"
        [baseRole]="baseRole"
        [featureHasGGCoin]="featureHasGGCoin"
        [showBuildId]="showBuildId"
        [releaseId]="releaseId"
        (logoutEvent)="logout()"
      ></gingr-sidenav-mobile>

      <div
        class="growMax"
        [ngClass]="{ 'pl-[90px]': !isInstaSection }"
        [class.mobile-mode]="
          screenWidth < 769 ||
          ((isAccountProfile || isProfileOverview || isAppointmentSection) &&
            screenWidth < 1280)
        "
        [class.largeLayoutPage]="isAccountProfile || isProfileOverview"
        [class.xlargeLayoutPage]="
          isAppointmentSection || isBookingDetailsSection
        "
      >
        <router-outlet></router-outlet>
      </div>
    </div>

    <div
      *ngIf="hasInstaButton"
      class="fixed left-0 bottom-0 bg-base-300 z-50 shadow block md:hidden w-full text-lg"
    >
      <div
        *ngIf="isUserClient() || currentUser === null; else gingr"
        class="py-3 px-4"
      >
        <button
          class="ms-auto btn btn-primary rounded shadow py-3 w-full"
          (click)="openInstantBooking()"
        >
          {{ 'HEADER.Start instant booking' | translate: locale.language }}
        </button>
      </div>

      <ng-template #gingr>
        <div class="flex flex-col" *ngIf="false">
          <div
            class="h-[6px] animate-w-0-100 from-[#00FF009B] to-[#00FF00] bg-gradient-to-r rounded-br-lg"
          ></div>
          <div class="flex py-3 px-4">
            <div
              class="flex gap-2 items-center justify-center w-1/2"
              (click)="openInstantBooking()"
            >
              <div
                class="border-[#B1199F] border-2 rounded-50% w-5 h-5 flex flex-col justify-center items-center"
              >
                <div class="bg-[#00FF00] w-2 h-2 rounded-50%"></div>
              </div>
              <span class="text-[#00FF00] capitalize text-base">{{
                ('INSTA.READY_NOW' | translate: locale.language) + '...'
              }}</span>
            </div>
            <button
              class="ms-auto btn bg-base-200 text-white rounded shadow py-2 w-1/2 text-lg font-normal"
              (click)="deactivateInsta()"
            >
              {{ 'INSTA.Deactivate' | translate: locale.language }}
            </button>
          </div>
        </div>
        <div class="py-3 px-4">
          <button
            class="ms-auto btn btn-primary bg-primary text-base-300 rounded shadow py-3 w-full text-lg"
            (click)="openInstantBooking()"
          >
            {{ 'COMMON.Find clients' | translate: locale.language }}
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<gingr-toast-show></gingr-toast-show>

<gingr-filters-v2
  [updateAndClose]="updateFilter"
  [hidden]="!showFilter || isInstaSection"
  [mobile]="screenWidth < 768"
  [class.new-filter]="screenWidth < 768"
  (closeFilters)="showFilter = !showFilter"
></gingr-filters-v2>

<gingr-find-gingr-filter
  *ngIf="showFilter && isInstaSection"
></gingr-find-gingr-filter>
