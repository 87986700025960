<!-- No errors -->
<div
  *ngIf="
    (creditBalance >= alertLimit && !isClient) ||
    (paymentStatus === paymentStatusEnum.Active &&
      creditBalance >= 0 &&
      isClient)
  "
  class="flex items-center flex-col md:flex-row"
>
  <div class="md:mr-4 mb-4 md:mb-0">
    <svg-icon
      src="/assets/svg-icons/common/status-success-circle.svg"
      [svgStyle]="{
        'width.px': 40,
        'height.px': 40,
        fill: 'rgb(0,255,0)'
      }"
    >
    </svg-icon>
  </div>

  <ng-container *ngIf="optionalProgress < 100">
    <div class="text-center px-4 md:px-0 text-sm leading-5">
      <span class="text-primary text-sm leading-5 capitalize me-1"
        >{{ 'ACCOUNT.hint' | translate: locale.language }}
      </span>
      <span class="text-sm leading-5">
        {{
          'ACCOUNT.proving some personal details improves chances'
            | translate: locale.language
        }}</span
      >
    </div>
  </ng-container>
  <ng-container *ngIf="optionalProgress === 100">
    <div class="text-center px-4 md:px-0">
      <span class="capitalize text-sm leading-5 text-primary me-1"
        >{{ 'INSTA.congratulations excl mark' | translate: locale.language }}
      </span>
      <span class="text-sm leading-5">
        {{
          (isClient
            ? 'ACCOUNT.you can now create bookings'
            : 'ACCOUNT.your profile is now published'
          ) | translate: locale.language
        }}</span
      >
    </div>
  </ng-container>

  <div
    class="w-full md:hidden flex flex-col gap-2.5"
    *ngIf="isClient && browseUserDetails"
  >
    <button
      class="justify-center gap-2 flex rounded py-2.5 border-primary text-primary bg-transparent border-2 capitalize md:hidden w-100 shadow mt-[20px] font-semibold"
      [routerLink]="['/gingrs/profile/' + browseUserDetails.id]"
    >
      <img
        [src]="browseUserDetails.avatarUrl"
        alt="avatar"
        class="w-[24px] h-[24px] rounded-full"
      />
      <span>{{
        ('BOOKINGS.BOOK' | translate: locale.language) +
          ' ' +
          browseUserDetails.displayName
      }}</span>
    </button>
    <button
      class="ms-auto justify-center gap-2 flex rounded bg-primary shadow py-2.5 w-full text-[#6B0064] font-semibold"
      [routerLink]="['/gingrs']"
    >
      <span class="first-letter:capitalize">
        {{ 'ACCOUNT.browse gingrs' | translate: locale.language }}
      </span>
    </button>
  </div>

  <ng-container *ngIf="isClient">
    <button
      *ngIf="browseUserDetails"
      class="border-2 border-primary text-primary bg-transparent hidden xl:flex gap-2.5 font-semibold ml-auto w-100 md:w-auto mt-4 md:mt-0 px-6 rounded text-base py-3 shadow-xs mr-3"
      [routerLink]="['/gingrs/profile/' + browseUserDetails?.id]"
    >
      <img
        [src]="browseUserDetails.avatarUrl"
        class="w-[24px] h-[24px] rounded-full"
        alt="avatar"
      />
      <span class="first-letter:capitalize">
        {{
          ('BOOKINGS.BOOK' | translate: locale.language) +
            ' ' +
            browseUserDetails?.displayName
        }}
      </span>
    </button>
    <button
      class="btn btn-primary hidden xl:flex w-100 md:w-auto mt-4 md:mt-0 rounded text-base py-3 shadow-xs"
      [ngClass]="{ 'px-6 ml-auto': !browseUserDetails }"
      [routerLink]="['/gingrs']"
    >
      <span class="first-letter:capitalize">
        {{ 'ACCOUNT.browse gingrs' | translate: locale.language }}
      </span>
    </button>
    <div
      class="xl:d-none fixed left-0 bottom-0 bg-base-300 py-3 px-4 z-50 shadow block md:hidden w-full"
    >
      <button
        class="ms-auto btn btn-primary rounded shadow py-3 w-full text-lg"
        [routerLink]="['/gingrs']"
      >
        <span class="first-letter:capitalize">
          {{ 'ACCOUNT.browse gingrs' | translate: locale.language }}
        </span>
      </button>
    </div>
  </ng-container>
</div>

<!-- Client negative balance -->
<div
  class="flex items-center justify-between"
  *ngIf="creditBalance < 0 && isClient"
>
  <div
    class="flex flex-col xl:flex-row items-center text-center xl:text-start text-sm"
  >
    <svg-icon
      class="mr-4 mb-3 xl:mb-0"
      src="/assets/svg-icons/common/status-error-circle.svg"
      [svgStyle]="{
        'width.px': 40,
        'height.px': 40,
        fill: '#ff0015'
      }"
    ></svg-icon>
    <span>
      <span class="text-primary mr-1">{{
        'CREDIT.New bookings are restricted excl' | translate: locale.language
      }}</span>
      <span class="text-white">{{
        'CREDIT.Clear your balance to continue booking'
          | translate: locale.language
      }}</span>
    </span>
  </div>
</div>

<!-- Client overdue invoices -->
<div
  class="flex items-center justify-between"
  *ngIf="
    creditBalance >= 0 &&
    isClient &&
    (paymentStatus === paymentStatusEnum.OverdueInvoicesLimit ||
      paymentStatus === paymentStatusEnum.OverdueInvoices)
  "
>
  <div
    class="flex flex-col items-center xl:flex-row xl:w-full xl:justify-between text-center text-sm xl:text-start"
  >
    <div class="flex flex-col xl:flex-row items-center">
      <svg-icon
        *ngIf="paymentStatus === paymentStatusEnum.OverdueInvoicesLimit"
        class="mr-4 mb-3 xl:mb-0"
        src="/assets/svg-icons/common/status-error-circle.svg"
        [svgStyle]="{
          'width.px': 40,
          'height.px': 40,
          fill: '#ff0015'
        }"
      ></svg-icon>
      <svg-icon
        *ngIf="paymentStatus === paymentStatusEnum.OverdueInvoices"
        class="mr-4 mb-3 xl:mb-0"
        src="/assets/svg-icons/common/status-alert-circle.svg"
        [svgStyle]="{
          'width.px': 40,
          'height.px': 40,
          fill: '#ffd640'
        }"
      ></svg-icon>
      <span>
        <span class="text-primary mr-1">{{
          'CREDIT.Important notice' | translate: locale.language
        }}</span>
        <span
          class="text-white"
          *ngIf="paymentStatus === paymentStatusEnum.OverdueInvoicesLimit"
          >{{
            'CREDIT.You have overdue invoices over limit'
              | translate: locale.language
          }}</span
        >
        <ng-container
          *ngIf="paymentStatus === paymentStatusEnum.OverdueInvoices"
        >
          <span class="text-white">{{
            'CREDIT.You have overdue invoice' | translate: locale.language
          }}</span>
          <span class="text-white font-bold">{{
            ' ' +
              ('CREDIT.Pay now to keep enjoying our services'
                | translate: locale.language)
          }}</span></ng-container
        >
      </span>
    </div>
    <button
      class="btn btn-primary md:w-auto mt-4 md:mt-0 !min-h-fit rounded py-2.5 shadow-xs"
      [routerLink]="['/wallet/invoices/overview']"
    >
      <span class="first-letter:capitalize">
        {{ 'CREDIT.Pay now' | translate: locale.language }}
      </span>
    </button>
  </div>
</div>

<!-- Escort alert balance -->
<div
  class="flex items-center justify-between"
  *ngIf="creditBalance < alertLimit && !isClient"
>
  <div
    class="flex flex-col xl:flex-row items-center text-center xl:text-start xl:w-3/4"
  >
    <svg-icon
      class="mr-4 mb-3 xl:mb-0"
      src="/assets/svg-icons/common/status-error-circle.svg"
      [svgStyle]="{
        'width.px': 40,
        'height.px': 40,
        fill: '#ff0015'
      }"
    ></svg-icon>
    <span>
      <span class="text-primary mr-1">{{
        (creditBalance < inactiveLimit
          ? 'CREDIT.Profile inactive'
          : 'CREDIT.Low balance'
        ) | translate: locale.language
      }}</span>
      <span class="text-white">{{
        (creditBalance < inactiveLimit
          ? 'CREDIT.Balance alert account inactive'
          : 'CREDIT.Balance alert'
        ) | translate: locale.language
      }}</span>
    </span>
  </div>

  <div
    class="hidden xl:inline-block shadow-xs cursor-pointer rounded first-letter:capitalize px-6 py-2.5 bg-primary text-[#6B0064] text-lg font-semibold"
    [routerLink]="'/wallet/overview'"
  >
    {{ 'CREDIT.top up now' | translate: locale.language }}
  </div>
</div>
