<div
  class="w-full max-w-screen-lg lg:px-8 mx-auto flex flex-col py-[48px] lg:py-5 h-100 md-h-auto"
  [class.pt-[16px]]="router.url === '/account/profile'"
  *ngIf="user()"
>
  <div
    class="px-4 md:px-0 mb-2 md:mb-8 h-smallDesktop:hidden h-Desktop:block"
    [class.hidden]="router.url !== '/account/profile' || windowWidth > 768"
    [class.visible]="router.url === '/account/profile' || windowWidth > 768"
  >
    <div class="bg-base-300 px-5 py-3 shadow rounded text-white">
      <missing-mandatory-progress
        *ngIf="mandatoryProgress < 100; else mandatoryCompleteNotLive"
        [role]="user().role"
      ></missing-mandatory-progress>

      <ng-template #mandatoryCompleteNotLive>
        <mandatory-complete-not-live
          *ngIf="
            !user().live &&
              user().profileApprovedStatus !==
                profileApprovedStatusTypes.APPROVED;
            else liveBanners
          "
          [profileApprovedStatus]="user().profileApprovedStatus"
          (publicProfileEvent)="publishProfile()"
        ></mandatory-complete-not-live>
      </ng-template>

      <ng-template #liveBanners>
        <live-account-banner
          [isClient]="isClient()"
          [creditBalance]="user().creditBalance"
          [optionalProgress]="optionalProgress"
          [browseUserDetails]="browseUserDetails"
          [paymentStatus]="user().paymentStatus"
        ></live-account-banner>
      </ng-template>
    </div>

    <div class="md:hidden mt-3">
      <ng-container *ngTemplateOutlet="viewProfileTemplate"></ng-container>
    </div>
    <div *ngIf="isClient()" class="block w-100 h-8 md:hidden"></div>
  </div>

  <div class="flex gap-16 h-100 md:h-auto">
    <div
      class="flex flex-col w-full px-4 pb-6 md:pb-4 lg:px-0 lg:max-w-[418px]"
      *ngIf="windowWidth >= 991 || router.url === '/account/profile'"
    >
      <span
        class="text-primary text-2xl font-semibold mb-[19px] md:mb-4 block"
        >{{ 'ACCOUNT.Mandatory' | translate: locale.language }}</span
      >

      <div
        class="rounded shadow h-4 w-100 bg-base-300 mb-[57px] relative block md:hidden"
      >
        <span
          class="absolute w-100 left-0 bottom-0 h-100 rounded-l"
          [class.bg-[#F3D551]]="
            mandatoryProgress > 0 && mandatoryProgress < 100
          "
          [class.bg-[#03FF00]]="mandatoryProgress === 100"
          [class.rounded-r]="mandatoryProgress === 100"
          [style.width]="mandatoryProgress + '%'"
        ></span>
        <span class="absolute bottom-[-24px] right-0 text-white">
          {{ mandatoryProgress }}%</span
        >
      </div>

      <div
        class="md:flex flex-wrap gap-2.5 md:gap-4 md:mb-8 mb-12 columns-2 md:columns-auto grid grid-cols-2"
      >
        <gingr-profile-tile
          *ngFor="let tile of mandatoryTiles"
          [tileData]="tile"
        ></gingr-profile-tile>
      </div>

      <span
        class="text-primary text-2xl font-semibold mb-[19px] md:mb-4 block"
        >{{ 'ACCOUNT.Optional' | translate: locale.language }}</span
      >

      <div
        class="rounded shadow h-4 w-100 bg-base-300 mb-[57px] relative block md:hidden"
      >
        <span
          class="absolute left-0 bottom-0 h-100 rounded-l"
          [class.bg-[#F3D551]]="optionalProgress > 0 && optionalProgress < 100"
          [class.bg-[#03FF00]]="optionalProgress === 100"
          [class.rounded-r]="optionalProgress === 100"
          [style.width]="optionalProgress + '%'"
        ></span>
        <span class="absolute bottom-[-24px] right-0 text-white"
          >{{ optionalProgress }}%</span
        >
      </div>

      <div
        class="md:flex flex-wrap gap-4 md:mb-0 mb-[30px] columns-2 md:columns-auto grid grid-cols-2"
      >
        <gingr-profile-tile
          *ngFor="let tile of optionalTiles"
          [tileData]="tile"
        ></gingr-profile-tile>
      </div>
    </div>

    <div
      class="w-full h-100 md:h-auto lg:max-w-[480px]"
      *ngIf="windowWidth >= 992 || router.url !== '/account/profile'"
    >
      <div class="hidden md:flex mb-5">
        <ng-container *ngTemplateOutlet="viewProfileTemplate"></ng-container>
      </div>
      <div
        class="px-4 md:px-0 md:mb-4 h-smallDesktop:block h-Desktop:hidden hidden"
      >
        <div class="bg-base-300 p-5 md:py-[14px] shadow rounded text-white">
          <ng-container *ngIf="mandatoryProgress < 100">
            <div class="flex items-center flex-col md:flex-row">
              <div class="md:mr-4 mb-4 md:mb-0">
                <svg-icon
                  src="/assets/svg-icons/common/status-alert-circle.svg"
                  [svgStyle]="{
                    'width.px': 40,
                    'height.px': 40,
                    fill: '#F3D551',
                    filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
                  }"
                >
                </svg-icon>
              </div>
              <div class="px-4 md:px-0">
                <span class="text-primary text-sm leading-5 me-1">{{
                  'ACCOUNT.welcome' | translate: locale.language
                }}</span>
                <span
                  class="text-sm leading-5"
                  *ngIf="
                    user().role === userRoleTypes.SERVICE_PROVIDER_BASIC ||
                    user().role === userRoleTypes.SERVICE_PROVIDER_PRO
                  "
                  >{{
                    'ACCOUNT.to publish your profile fill out mandatory'
                      | translate: locale.language
                  }}</span
                >
                <span
                  class="text-sm leading-5"
                  *ngIf="
                    user().role === userRoleTypes.CLIENT_BASIC ||
                    user().role === userRoleTypes.CLIENT_PRO
                  "
                  >{{
                    'ACCOUNT.to activate bookings fill out mandatory'
                      | translate: locale.language
                  }}</span
                >
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="mandatoryProgress === 100">
            <ng-container
              *ngIf="
                !user().live &&
                user().profileApprovedStatus !==
                  profileApprovedStatusTypes.PENDING_APPROVAL
              "
            >
              <div class="flex items-center flex-col md:flex-row">
                <div class="md:mr-4 mb-4 md:mb-0">
                  <svg-icon
                    src="/assets/svg-icons/common/status-error-circle.svg"
                    [svgStyle]="{
                      'width.px': 40,
                      'height.px': 40,
                      fill: 'rgb(255,0,22)',
                      filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
                    }"
                  >
                  </svg-icon>
                </div>
                <span class="px-4 md:px-0 text-sm leading-5">{{
                  'ACCOUNT.thank you for updating profile'
                    | translate: locale.language
                }}</span>
                <button
                  class="btn btn-primary hidden xl:flex ml-auto w-100 md:w-auto mt-4 md:mt-0 px-6 rounded text-base py-3 shadow-xs"
                  (click)="publishProfile()"
                >
                  <span class="first-letter:capitalize">
                    {{ 'ACCOUNT.publish profile' | translate: locale.language }}
                  </span>
                </button>
                <div
                  class="xl:d-none fixed left-0 bottom-0 bg-base-300 py-3 px-4 z-[52] shadow block md:hidden w-full"
                >
                  <button
                    class="ms-auto btn btn-primary rounded shadow py-3 w-full text-lg"
                    (click)="publishProfile()"
                  >
                    <span class="first-letter:capitalize">
                      {{
                        'ACCOUNT.publish profile' | translate: locale.language
                      }}
                    </span>
                  </button>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                user().profileApprovedStatus ===
                profileApprovedStatusTypes.PENDING_APPROVAL
              "
            >
              <div class="flex items-center flex-col md:flex-row">
                <div class="md:mr-4 mb-4 md:mb-0">
                  <svg-icon
                    src="/assets/svg-icons/common/status-pending-circle.svg"
                    [svgStyle]="{
                      'width.px': 40,
                      'height.px': 40,
                      fill: 'rgb(255,173,60)',
                      filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
                    }"
                  >
                  </svg-icon>
                </div>
                <span class="px-4 md:px-0 text-sm leading-5">{{
                  'ACCOUNT.profile pending to be published'
                    | translate: locale.language
                }}</span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                user().live &&
                user().profileApprovedStatus ===
                  profileApprovedStatusTypes.APPROVED
              "
            >
              <div class="flex items-center flex-col md:flex-row">
                <div class="md:mr-4 mb-4 md:mb-0">
                  <svg-icon
                    src="/assets/svg-icons/common/status-success-circle.svg"
                    [svgStyle]="{
                      'width.px': 40,
                      'height.px': 40,
                      fill: 'rgb(0,255,0)',
                      filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
                    }"
                  >
                  </svg-icon>
                </div>

                <ng-container *ngIf="optionalProgress < 100">
                  <div class="px-4 md:px-0">
                    <span class="text-primary text-sm leading-5 capitalize me-1"
                      >{{ 'ACCOUNT.hint' | translate: locale.language }}
                    </span>
                    <span class="text-sm leading-5">
                      {{
                        'ACCOUNT.proving some personal details improves chances'
                          | translate: locale.language
                      }}</span
                    >
                  </div>
                </ng-container>
                <ng-container *ngIf="optionalProgress === 100">
                  <div class="px-4 md:px-0 me-2">
                    <span class="capitalize text-primary text-sm leading-5 me-1"
                      >{{
                        'INSTA.congratulations excl mark'
                          | translate: locale.language
                      }}
                    </span>
                    <span class="text-sm leading-5">
                      {{
                        'ACCOUNT.your profile is now published'
                          | translate: locale.language
                      }}</span
                    >
                  </div>
                </ng-container>
                <ng-container *ngIf="isClient()">
                  <button
                    class="btn btn-primary hidden xl:flex ml-auto w-100 md:w-auto mt-4 md:mt-0 px-6 rounded text-base py-3 shadow-xs"
                    [routerLink]="['/gingrs']"
                  >
                    <span class="first-letter:capitalize">
                      {{ 'ACCOUNT.browse gingrs' | translate: locale.language }}
                    </span>
                  </button>
                  <div
                    class="xl:d-none fixed left-0 bottom-0 bg-base-300 py-3 px-4 z-50 shadow block md:hidden w-full"
                  >
                    <button
                      class="ms-auto btn btn-primary rounded shadow py-3 w-full text-lg"
                      [routerLink]="['/gingrs']"
                    >
                      <span class="first-letter:capitalize">
                        {{
                          'ACCOUNT.browse gingrs' | translate: locale.language
                        }}
                      </span>
                    </button>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="md:hidden">
          <ng-container *ngTemplateOutlet="viewProfileTemplate"></ng-container>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #viewProfileTemplate>
  <a
    (click)="goToProfilePreview(!isClient())"
    class="flex w-100 text-primary items-center content-center justify-end flex-row text-sm capitalize cursor-pointer"
    [ngClass]="{ 'opacity-50 cursor-default': mandatoryProgress !== 100 }"
    >{{ 'ACCOUNT.View profile' | translate: locale.language }}
    <div class="md:mr-4 md:mb-0 ms-2">
      <svg-icon
        src="/assets/svg-icons/common/eye-show-circle.svg"
        [svgStyle]="{
          'width.px': 28,
          'height.px': 28,
          fill: 'rgb(255,214,64)',
          filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
        }"
      >
      </svg-icon>
    </div>
  </a>
</ng-template>
