<div
  class="flex items-center flex-col md:flex-row"
  *ngIf="
    profileApprovedStatus !== profileApprovedStatusTypes.PENDING_APPROVAL;
    else pending
  "
>
  <div class="md:mr-4 mb-4 md:mb-0">
    <svg-icon
      src="/assets/svg-icons/common/status-error-circle.svg"
      [svgStyle]="{
        'width.px': 40,
        'height.px': 40,
        fill: 'rgb(255,0,22)',
        filter: 'drop-shadow(0px 3px 6px rgb(0 0 0 / 0.16))'
      }"
    >
    </svg-icon>
  </div>
  <span class="text-center text-sm leading-5 px-4 md:px-0">{{
    'ACCOUNT.thank you for updating profile' | translate: locale.language
  }}</span>
  <button
    class="btn btn-primary hidden xl:flex ml-auto w-100 md:w-auto mt-4 md:mt-0 px-6 rounded text-base py-3 shadow-xs"
    (click)="publicProfileEvent.next(true)"
  >
    <span class="first-letter:capitalize">
      {{ 'ACCOUNT.publish profile' | translate: locale.language }}
    </span>
  </button>
  <div
    class="xl:d-none fixed left-0 bottom-0 bg-base-300 py-3 px-4 z-[52] shadow block md:hidden w-full"
  >
    <button
      class="ms-auto btn btn-primary rounded shadow py-3 w-full text-lg"
      (click)="publicProfileEvent.next(true)"
    >
      <span class="first-letter:capitalize">
        {{ 'ACCOUNT.publish profile' | translate: locale.language }}
      </span>
    </button>
  </div>
</div>
<ng-template #pending>
  <div class="flex items-center flex-col md:flex-row">
    <div class="md:mr-4 mb-4 md:mb-0">
      <svg-icon
        src="/assets/svg-icons/common/status-pending-circle.svg"
        [svgStyle]="{
          'width.px': 40,
          'height.px': 40,
          fill: 'rgb(255,173,60)'
        }"
      >
      </svg-icon>
    </div>
    <span class="text-center text-sm leading-5 px-4 md:px-0">{{
      'ACCOUNT.profile pending to be published' | translate: locale.language
    }}</span>
  </div>
</ng-template>
