import {
  Component,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgSwitch,
  NgSwitchCase,
} from '@angular/common';
import { FooterButtonComponent } from '@app/instafeature/components/footer-button/footer-button.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { MatIconModule } from '@angular/material/icon';
import { NgxSliderModule, Options } from 'ngx-slider-v2';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  getAppConfig,
  getUserLocation,
} from '@app/shared/reducers/shared.selectors';
import * as _ from 'lodash-es';
import { Config, Language, SummaryLocation } from '@app/shared/models';
import { ServiceTypes } from '@app/instafeature/models/service-types';
import { FilterV2Service } from '@app/shared/services/filter-v2.service';
import { LocationService, ProfilesService } from '@app/shared/services';
import { LocationSelectorInstaComponent } from '@app/shared/components/location-selector-insta/location-selector-insta.component';
import {
  getUserLocationAction,
  storeFilterLocation,
} from '@app/shared/actions/shared.actions';
import { FilterCity } from '@app/shared/models/filter-city';
import { SvgIconComponent } from 'angular-svg-icon';
import { ConnectType } from '@app/instafeature/models/connect-type';
import { getCurrentUser } from '@app/shared/reducers/user.selectors';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'gingr-filters-v2',
  standalone: true,
  templateUrl: './filters-v2.component.html',
  styleUrls: ['./filters-v2.component.scss'],
  imports: [
    AsyncPipe,
    FooterButtonComponent,
    FormsModule,
    L10nTranslatePipe,
    MatIconModule,
    NgForOf,
    NgIf,
    NgxSliderModule,
    ReactiveFormsModule,
    NgClass,
    LocationSelectorInstaComponent,
    SvgIconComponent,
  ],
})
export class FiltersV2Component implements OnInit {
  @Input() mobile = false;
  @Output() closeFilters = new EventEmitter();

  private readonly geolocationService = inject(LocationService);
  private readonly filterV2Service = inject(FilterV2Service);
  private readonly store = inject(Store);
  private readonly profileService = inject(ProfilesService);
  private readonly authService = inject(AuthService);
  readonly locale = inject(L10N_LOCALE);

  readonly userLocation = this.store.selectSignal(getUserLocation);
  readonly currentUser = this.store.selectSignal(getCurrentUser);

  ageFloor = 18;
  ageCeil = 100;
  priceFloor = 0;
  priceCeil = 500;
  ratingFloor = 1;
  ratingCeil = 10;
  radiusFloor = 0;
  radiusCeil = 50;
  resultsCount$: Observable<number>;
  priceOptions: Options = {
    floor: this.priceFloor,
    ceil: this.priceCeil,
    hideLimitLabels: true,
    hidePointerLabels: false,
  };
  ratingOptions: Options = {
    floor: this.ratingFloor,
    ceil: this.ratingCeil,
    hideLimitLabels: false,
    hidePointerLabels: false,
  };
  ageOptions: Options = {
    floor: this.ageFloor,
    ceil: this.ageCeil,
    showSelectionBar: true,
    hideLimitLabels: true,
    hidePointerLabels: false,
  };
  radiusOptions: Options = {
    floor: this.radiusFloor,
    ceil: this.radiusCeil,
    hideLimitLabels: false,
    hidePointerLabels: false,
  };
  services: any;
  language: Language[] = [];
  locationSelected = false;
  rating: number = null;
  servicesType = ServiceTypes;
  formGroupTopTypes: FormGroup;
  genderFormGroup: FormGroup;
  sexualityFormGroup: FormGroup;
  servicesFormGroup: FormGroup;
  languagesFormGroup: FormGroup;
  hairColorFormGroup: FormGroup;
  ethnicityFormGroup: FormGroup;
  bodyTypeFormGroup: FormGroup;
  breastSizeFormGroup: FormGroup;
  formGroupLoaded = false;
  requestedCurrentLocation = false;

  ageLow = this.ageFloor;
  ageHigh = this.ageCeil;
  ageVerified = false;
  priceLow = this.priceFloor;
  priceHigh = this.priceCeil;
  servicesByCategory = {};
  destroyRef = inject(DestroyRef);
  config: Config;
  address: string;

  expanded = {
    gender: false,
    sexuality: false,
    price: false,
    age: false,
    services: false,
    languages: false,
    hairColor: false,
    ethnicity: false,
    bodyType: false,
    breastSize: false,
    rating: false,
  };
  breastSizes = ['A', 'B', 'C', 'D', 'E', 'F+'];
  selectedRadius = 15;
  showRadiusSlider = false;
  enterSpecificAddress = false;
  locationAccess = false;
  filterRegions: FilterCity[] = [];
  currentAddress: SummaryLocation;

  @Input() set updateAndClose({ update }) {
    if (!update) {
      return;
    }
    this.filter();
  }

  constructor() {
    effect(() => {
      if (
        this.userLocation() &&
        this.requestedCurrentLocation &&
        !this.userLocation().isFallback
      ) {
        this.requestedCurrentLocation = false;
        this.currentAddress = this.userLocation();
        this.address = this.currentAddress.formatted_address;
        this.filterV2Service.setUserLocation(
          this.currentAddress.latitude,
          this.currentAddress.longitude
        );
        this.store.dispatch(storeFilterLocation({ data: this.currentAddress }));
        this.filterV2Service.updateCount();
      }
    });
  }

  ngOnInit() {
    this.filterRegions = this.filterV2Service.filterRegions;
    this.store
      .select(getAppConfig)
      .pipe(
        filter((config) => !!config),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((config) => {
        this.config = _.cloneDeep(config);

        this.constructFormGroupsAttributes();
      });

    this.resultsCount$ = this.profileService.totalProfilesCount.asObservable();
  }

  constructFormGroupsAttributes() {
    if (
      this.filterV2Service.filter.ageLow &&
      this.filterV2Service.filter.ageHigh
    ) {
      this.ageHigh = this.filterV2Service.filter.ageHigh;
      this.ageLow = this.filterV2Service.filter.ageLow;
      this.ageVerified = this.filterV2Service.filter.ageVerified;
    }
    if (
      this.filterV2Service.filter.priceLow &&
      this.filterV2Service.filter.priceHigh
    ) {
      this.priceHigh = this.filterV2Service.filter.priceHigh;
      this.priceLow = this.filterV2Service.filter.priceLow;
    }
    this.constructFormGroupTop();
    this.constructGenderFormGroup();
    this.constructSexualityFormGroup();
    this.constructBreastFormGroup();
    this.constructHairColorFormGroup();
    this.constructLanguagesFormGroup();
    this.constructBodyTypesFormGroup();
    this.constructOriginFormGroup();
    this.constructServicesFormGroup();
    this.sortAndFilterServices();
    this.formGroupLoaded = true;
  }

  sortAndFilterServices() {
    this.servicesType.forEach((serviceType: any) => {
      this.servicesByCategory[serviceType.type] = this.config.services
        .filter((s: any) => s.group === serviceType.type)
        .sort((service1, service2) => (service1.name > service2.name ? 1 : -1));
    });
  }

  constructGenderFormGroup() {
    const controls = {};
    this.config.genders.forEach(
      (sex) =>
        (controls[sex.id] = new FormControl(
          this.filterV2Service.filter.gender.findIndex((g) => g === sex.id) > -1
        ))
    );
    this.genderFormGroup = new FormGroup(controls);

    this.genderFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          gender: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterV2Service.updateCount();
      });
  }

  constructSexualityFormGroup() {
    const controls = {};
    this.config.sexualities.forEach(
      (sexuality) =>
        (controls[sexuality.id] = new FormControl(
          this.filterV2Service.filter.sexuality.findIndex(
            (g) => g === sexuality.id
          ) > -1
        ))
    );
    this.sexualityFormGroup = new FormGroup(controls);

    this.sexualityFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          sexuality: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterV2Service.updateCount();
      });
  }

  constructServicesFormGroup() {
    const controls = {};
    this.config.services.forEach(
      (service) =>
        (controls[service.id] = new FormControl(
          this.filterV2Service.filter.services.findIndex(
            (g) => g === service.id
          ) > -1
        ))
    );
    this.servicesFormGroup = new FormGroup(controls);

    this.servicesFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          services: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterV2Service.updateCount();
      });
  }

  constructLanguagesFormGroup() {
    const controls = {};
    this.config.languages = this.config.languages
      .filter((lang) => lang.name !== 'N/A')
      .sort((lang1, lang2) => (lang1.name > lang2.name ? 1 : -1));
    this.config.languages.forEach(
      (language) =>
        (controls[language.id] = new FormControl(
          this.filterV2Service.filter.language.findIndex(
            (g) => g === language.id
          ) > -1
        ))
    );
    this.languagesFormGroup = new FormGroup(controls);

    this.languagesFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          language: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterV2Service.updateCount();
      });
  }

  constructHairColorFormGroup() {
    const controls = {};
    this.config.hairColors.forEach(
      (hair) =>
        (controls[hair.id] = new FormControl(
          this.filterV2Service.filter.hairColor.findIndex(
            (g) => g === hair.id
          ) > -1
        ))
    );
    this.hairColorFormGroup = new FormGroup(controls);

    this.hairColorFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          hairColor: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterV2Service.updateCount();
      });
  }

  constructOriginFormGroup() {
    const controls = {};
    this.config.origins.forEach(
      (origin) =>
        (controls[origin.id] = new FormControl(
          this.filterV2Service.filter.ethnicity.findIndex(
            (g) => g === origin.id
          ) > -1
        ))
    );
    this.ethnicityFormGroup = new FormGroup(controls);

    this.ethnicityFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          ethnicity: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterV2Service.updateCount();
      });
  }

  constructBodyTypesFormGroup() {
    const controls = {};
    this.config.bodies.forEach(
      (service) =>
        (controls[service.id] = new FormControl(
          this.filterV2Service.filter.bodyType.findIndex(
            (g) => g === service.id
          ) > -1
        ))
    );
    this.bodyTypeFormGroup = new FormGroup(controls);

    this.bodyTypeFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          bodyType: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => +key),
        };
        this.filterV2Service.updateCount();
      });
  }

  constructBreastFormGroup() {
    const controls = {};
    this.breastSizes.forEach(
      (size) =>
        (controls[size] = new FormControl(
          this.filterV2Service.filter.breastSize.findIndex((g) => g === size) >
            -1
        ))
    );
    this.breastSizeFormGroup = new FormGroup(controls);

    this.breastSizeFormGroup.valueChanges
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          breastSize: Object.keys(change)
            .filter((key) => !!change[key])
            .map((key) => key),
        };
        this.filterV2Service.updateCount();
      });
  }

  constructFormGroupTop() {
    const controls = {};
    controls['favourites'] = new FormControl(
      this.filterV2Service.filter.favourites
    );
    controls['new'] = new FormControl(this.filterV2Service.filter.new);
    controls['verified'] = new FormControl(
      this.filterV2Service.filter.verified
    );
    controls['video'] = new FormControl(this.filterV2Service.filter.video);
    controls['stdTest'] = new FormControl(this.filterV2Service.filter.stdTest);
    controls['search'] = new FormControl(this.filterV2Service.filter.search);
    controls['voice'] = new FormControl(this.filterV2Service.filter.voice);
    controls['readyNow'] = new FormControl(
      this.filterV2Service.filter.readyNow
    );
    controls['theirPlace'] = new FormControl(
      this.filterV2Service.filter.callType === ConnectType.INCALL ||
        this.filterV2Service.filter.callType === ConnectType.BOTH
    );
    controls['myPlace'] = new FormControl(
      this.filterV2Service.filter.callType === ConnectType.OUTCALL ||
        this.filterV2Service.filter.callType === ConnectType.BOTH
    );

    this.formGroupTopTypes = new FormGroup(controls);

    this.formGroupTopTypes.valueChanges
      .pipe(debounceTime(50), takeUntilDestroyed(this.destroyRef))
      .subscribe((change) => {
        if (change.favourites && !this.currentUser()) {
          this.authService.loginWithRedirect({
            appState: {
              target: '/after-login',
            },
          });
          return;
        }

        this.filterV2Service.filter = {
          ...this.filterV2Service.filter,
          favourites: change.favourites,
          new: change.new,
          verified: change.verified,
          video: change.video,
          stdTest: change.stdTest,
          voice: change.voice,
          search: change.search,
          callType:
            change.theirPlace && change.myPlace
              ? ConnectType.BOTH
              : change.theirPlace
                ? ConnectType.INCALL
                : change.myPlace
                  ? ConnectType.OUTCALL
                  : null,
          readyNow: change.readyNow,
        };
        this.filterV2Service.updateCount();
      });
  }

  expandItem(property: string) {
    if (!this.expanded[property]) {
      Object.keys(this.expanded).forEach((key) => (this.expanded[key] = false));
    }
    this.expanded[property] = !this.expanded[property];
  }

  changeAge() {
    this.filterV2Service.filter.ageLow = this.ageLow;
    this.filterV2Service.filter.ageHigh = this.ageHigh;
    this.filterV2Service.filter.ageVerified = this.ageVerified;
    this.filterV2Service.updateCount();
  }

  changePrices() {
    this.filterV2Service.filter.priceLow = this.priceLow;
    this.filterV2Service.filter.priceHigh = this.priceHigh;
    this.filterV2Service.updateCount();
  }

  changeRating() {
    this.filterV2Service.filter.rating = this.rating;
    this.filterV2Service.updateCount();
  }

  changeRadius(value: number) {
    this.filterV2Service.filter.radius = value;
    this.filterV2Service.updateCount();
  }

  resetFilter() {
    this.resetSelectedRegions();
    this.selectedRadius = 15;
    this.showRadiusSlider = false;
    this.enterSpecificAddress = false;
    this.resetSliderValues();
    this.filterV2Service.resetFilters(this.filterV2Service.filter.userRoles);
    this.filterV2Service.setUserLocation(
      this.userLocation()?.latitude,
      this.userLocation()?.longitude
    );
    this.filterV2Service.updateCount();
    this.resetForms();
  }

  resetSliderValues() {
    this.rating = null;
    this.priceLow = this.priceFloor;
    this.priceHigh = this.priceCeil;
    this.ageLow = this.ageFloor;
    this.ageHigh = this.ageCeil;
    this.ageVerified = false;
  }

  resetForms() {
    this.formGroupTopTypes.reset();
    this.ethnicityFormGroup.reset();
    this.bodyTypeFormGroup.reset();
    this.languagesFormGroup.reset();
    this.breastSizeFormGroup.reset();
    this.genderFormGroup.reset();
    this.hairColorFormGroup.reset();
    this.servicesFormGroup.reset();
    this.sexualityFormGroup.reset();
  }

  closeFilter() {
    this.closeFilters.next(true);
  }

  filter() {
    this.filterV2Service.updateSearch();
    this.closeFilter();
  }

  locationSelectorChanged(data: any) {
    const location = this.geolocationService.getLocationFromGeocoderResult([
      data,
    ]);
    this.store.dispatch(storeFilterLocation({ data: location }));
    this.filterV2Service.setUserLocation(location.latitude, location.longitude);
    this.address = location.formatted_address;
    this.filterV2Service.updateCount();
  }

  askForLocationAccess() {
    if (!this.locationAccess) {
      this.locationAccess = true;
      this.store.dispatch(getUserLocationAction({ askAccess: true }));
      this.requestedCurrentLocation = true;
    }
    this.showRadiusSlider = !this.showRadiusSlider;
    this.filterV2Service.filter.radius = this.showRadiusSlider
      ? this.selectedRadius
      : null;
    this.resetSelectedRegions();
    this.filterV2Service.updateCount();
  }

  enterAddress(): void {
    this.enterSpecificAddress = !this.enterSpecificAddress;
    this.showRadiusSlider = true;
    this.filterV2Service.filter.radius = this.selectedRadius;
    this.resetSelectedRegions();
    this.filterV2Service.updateCount();
  }

  selectRegion(region: FilterCity) {
    this.enterSpecificAddress = false;
    this.showRadiusSlider = false;
    this.selectedRadius = 15;
    region.selected = !region.selected;
    const selectedRegions = this.filterRegions.filter((item) => item.selected);
    let admAreas: string[] = [];
    selectedRegions.forEach(
      (item) => (admAreas = [...admAreas, ...item.administrativeAreas])
    );
    this.filterV2Service.filter.administrativeAreas = admAreas;
    this.filterV2Service.updateCount();
  }

  resetSelectedRegions() {
    this.filterRegions.forEach((region) => (region.selected = false));
    this.filterV2Service.filter.administrativeAreas = [];
  }

  enterSpecificAddressControl() {
    this.enterSpecificAddress = !this.enterSpecificAddress;
    if (!this.enterSpecificAddress) {
      if (!this.currentAddress) {
        this.askForLocationAccess();
      } else {
        this.filterV2Service.filter.longitude = this.currentAddress.longitude;
        this.filterV2Service.filter.latitude = this.currentAddress.latitude;
        this.filterV2Service.updateCount();
      }
    }
  }

  formHasSelected(formGroup: FormGroup) {
    for (const controlsKey in formGroup.controls) {
      if (formGroup.controls[controlsKey].value) {
        return true;
      }
    }
    return false;
  }
}
