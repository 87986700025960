import { Routes } from '@angular/router';
import { LocationGuard } from '@app/shared/guards/location.guard';
import { AuthGuard } from '@app/shared/guards';
import { AcceptReferralComponent } from '@app/account/containers/referral/accept-referral/accept-referral.component';
import { AuthCallbackComponent } from '@app/auth/components/auth-callback/auth-callback.component';
import { AfterLoginComponent } from '@app/auth/components/after-login/after-login.component';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'gingrs',
    pathMatch: 'full',
  },
  {
    path: 'book',
    loadChildren: () =>
      import('./book/book.routing').then((m) => m.bookRouting),
  },
  {
    path: 'gingrs',
    loadChildren: () =>
      import('./profiles/profiles.routing').then((m) => m.profilesRoutes),
  },
  {
    path: 'clients',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./clients/clients.routing').then((m) => m.clientsRoutes),
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.routing').then((m) => m.infoRoutes),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./account/account.routing').then((m) => m.accountRoutes),
  },
  {
    path: 'instafeature',
    canActivate: [LocationGuard],
    loadChildren: () =>
      import('./instafeature/instafeature.routing').then((m) => m.instaRoutes),
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./homepage/homepage.routing').then((m) => m.homepageRoutes),
  },
  {
    path: 'wallet',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@app/wallet/wallet.routing').then((m) => m.walletRouting),
  },
  {
    path: 'auth-callback',
    component: AuthCallbackComponent,
  },
  {
    path: 'after-login',
    component: AfterLoginComponent,
  },
  {
    path: 'chat',
    loadChildren: () =>
      import('./stream-chat/stream-chat.routing').then((m) => m.chatRoutes),
    canActivate: [AuthGuard],
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./welcome/welcome.routing').then((m) => m.welcomeRoutes),
  },
  {
    path: 'referral/:code',
    component: AcceptReferralComponent,
  },
  { path: '**', redirectTo: '/gingrs' },
];
